const state = () => ({
    services: [],
    service: null,
    rules: [
        {
            key: 'operationType',
            value: [
                'R1','R2','R3','R4','R5','R6','R7','R8','R9','R10','R11','R12','R13',
                'D1','D2','D3','D4','D5','D6','D7','D8','D9','D10','D11','D12','D13','D14','D15'
            ]
        },
        { key: 'cer' },
        { key: 'dangerous' },
        { 
            key: 'hp',
            value: ['HP1','HP2','HP3','HP4','HP5','HP6','HP7','HP8','HP9','HP10','HP11','HP12','HP13','HP14','HP15'] 
        },
        { key: 'physicalState' },
        { key: 'producer' },
        { key: 'recipient' },
        { key: 'transporter' },
        { key: 'broker' },
    ]
})

export default state;