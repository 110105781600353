import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_INVOICES] : (state,{invoices}) => {
    state.invoices = invoices
  },
  [TYPES.SET_INVOICE] : (state,{ invoice }) => {
    state.invoice = invoice
  },
  [TYPES.SET_DATASET]: (state,{ key, dataset }) => {
    state.datasets[key] = dataset
  }
}

export default mutations