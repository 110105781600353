import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_TRAILERS] : (state,{trailers}) => {
    state.trailers = trailers
  },
  [TYPES.SET_TRAILER] : (state,{ trailer }) => {
    state.trailer = trailer
  },
  [TYPES.SET_AVERAGE_BILL_RATE] : (state, { average }) => {
    state.averageBillRate = average
  }
}

export default mutations