export const CompanyInvoicesRoutes = [
  {
    path: "invoices/active",
    name: "Company.activeInvoices.index",
    component: () =>
      import(/* webpackChunkName: "group-invoices" */ "@/views/CompanyInvoicesIndex.active.vue"),
    meta: {
      permissions: ["invoices:read"],
    },
  },
  {
    path: "invoices/passive",
    name: "Company.passiveInvoices.index",
    component: () =>
      import(/* webpackChunkName: "group-invoices" */ "@/views/CompanyInvoicesIndex.passive.vue"),
    meta: {
      permissions: ["invoices:read"],
    },
  },
  {
    path: "invoices/:id",
    name: "Company.invoices.show",
    component: () =>
      import(/* webpackChunkName: "group-invoices" */ "@/views/CompanyInvoicesShow.vue"),
    meta: {
      permissions: ["invoices:write"],
    },
  },
];
