import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_MOVEMENTS] : (state,{movements}) => {
    state.movements = movements
  },
  [TYPES.SET_MOVEMENT] : (state,{ movement }) => {
    state.movement = movement
  },
}

export default mutations