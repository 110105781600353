// authorizations mutations
export const SET_AUTHORIZATIONS = "SET_AUTHORIZATIONS";
export const SET_AUTHORIZATION = "SET_AUTHORIZATION";
export const ADD_AUTHORIZATION = "ADD_AUTHORIZATION";
export const UPDATE_AUTHORIZATION = "UPDATE_AUTHORIZATION";
export const REMOVE_AUTHORIZATION = "REMOVE_AUTHORIZATION";

// checks mutations
export const SET_CHECK = "SET_CHECK";
export const CHECKS = "CHECKS";

// companies mutations
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_COMPANY = "SET_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";

// dashboards mutations
export const SET_DASHBOARD = "SET_DASHBOARD";

// drivers mutations
export const SET_DRIVERS = "SET_DRIVERS";
export const SET_DRIVER = "SET_DRIVER";
export const SET_DRIVER_AVERAGE_BILL_RATE = "SET_DRIVER_AVERAGE_BILL_RATE";

// forms mutations
export const SET_FORM = "SET_FORM";
export const SET_FORMS = "SET_FORMS";
export const SET_FILTER = "SET_FILTER";
export const SET_PER_TONS = "SET_PER_TONS";
export const SET_ANALYTICAL_DATA = "SET_ANALYTICAL_DATA";
export const SET_SHOW_STANDARD = "SET_SHOW_STANDARD";
export const TOGGLE_CATEGORY_COLUMN = "TOGGLE_CATEGORY_COLUMN";
export const UPDATE_FORM = "UPDATE_FORM";

// general accounts mutations
export const SET_GENERAL_ACCOUNTS = "SET_GENERAL_ACCOUNTS";
export const SET_GENERAL_ACCOUNT = "SET_GENERAL_ACCOUNT";
export const UPDATE_GENERAL_ACCOUNT = "UPDATE_GENERAL_ACCOUNT";
export const ADD_GENERAL_ACCOUNT = "ADD_GENERAL_ACCOUNT";
export const REMOVE_GENERAL_ACCOUNT = "REMOVE_GENERAL_ACCOUNT";

// global filter mutations
export const SET_YEAR_FILTER = "SET_YEAR_FILTER";
export const SET_MONTHS_RANGE_FILTER = "SET_MONTHS_RANGE_FILTER";
export const SET_DAY_FILTER = "SET_DAY_FILTER";
export const SET_AUTHORIZATIONS_FILTER = "SET_AUTHORIZATIONS_FILTER";
export const SET_SERVICES_FILTER = "SET_SERVICES_FILTER";
export const SET_CER_CODES_FILTER = "SET_CER_CODES_FILTER";
export const SET_AVAILABLE_CER_CODES = "SET_AVAILABLE_CER_CODES";

// invoices mutations
export const SET_INVOICES = "SET_INVOICES";
export const SET_INVOICE = "SET_INVOICE";
export const SET_DATASET = "SET_DATASET";

// local units mutations
export const SET_LOCAL_UNITS = "SET_LOCAL_UNITS";
export const SET_LOCAL_UNIT = "SET_LOCAL_UNIT";
export const UPDATE_LOCAL_UNIT = "UPDATE_LOCAL_UNIT";
export const REMOVE_LOCAL_UNIT = "REMOVE_LOCAL_UNIT";

export const SET_MOVEMENTS = "SET_MOVEMENTS";
export const SET_MOVEMENT = "SET_MOVEMENT";

// notifications
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// options mutations
export const SET_OPTIONS = "SET_OPTIONS";
export const SET_DEFAULT_OPTIONS = "SET_DEFAULT_OPTIONS";
export const UPDATE_OPTION = "UPDATE_OPTION";
export const ADD_OPTION = "ADD_OPTION";

// reclassified accounts mutations
export const SET_RECLASSIFIED_ACCOUNTS = "SET_RECLASSIFIED_ACCOUNTS";
export const SET_RECLASSIFIED_ACCOUNT = "SET_RECLASSIFIED_ACCOUNT";
export const ADD_RECLASSIFIED_ACCOUNT = "ADD_RECLASSIFIED_ACCOUNT";
export const UPDATE_RECLASSIFIED_ACCOUNT = "UPDATE_RECLASSIFIED_ACCOUNT";
export const REMOVE_ACCOUNT = "REMOVE_ACCOUNT";
// reclassified accounts categories types
export const SET_RECLASSIFIED_ACCOUNT_TYPES = "SET_RECLASSIFIED_ACCOUNT_TYPES";
// reclassified accounts categories mutations
export const SET_RECLASSIFIED_ACCOUNT_CATEGORIES = "SET_RECLASSIFIED_ACCOUNT_CATEGORIES";
export const UPDATE_RECLASSIFIED_ACCOUNT_CATEGORY = "UPDATE_RECLASSIFIED_ACCOUNT_CATEGORY";
// reclassified accounts natures mutations
export const SET_RECLASSIFIED_ACCOUNT_NATURES = "SET_RECLASSIFIED_ACCOUNT_NATURES";
export const ADD_RECLASSIFIED_ACCOUNT_NATURE = "ADD_RECLASSIFIED_ACCOUNT_NATURE";
export const UPDATE_RECLASSIFIED_ACCOUNT_NATURE = "UPDATE_RECLASSIFIED_ACCOUNT_NATURE";
export const REMOVE_NATURE = "REMOVE_NATURE";

// registers mutations
export const SET_REGISTERS = "SET_REGISTERS";
export const SET_REGISTER = "SET_REGISTER";
export const SET_UNREGISTERED = "SET_UNREGISTERED";
export const UPDATE_REGISTER = "UPDATE_REGISTER";
export const SET_UNREGISTERED_COUNT = "SET_UNREGISTERED_COUNT";
export const ADD_FORM_TO_GROUP = "ADD_FORM_TO_GROUP";
export const REMOVE_FORM_FROM_GROUP = "REMOVE_FORM_FROM_GROUP";
export const REMOVE_ALL_FORMS_FROM_GROUP = "REMOVE_ALL_FORMS_FROM_GROUP";
export const SELECT_GROUP = "SELECT_GROUP";
export const UPDATE_FORMS_WITH_GROUP_DATA = "UPDATE_FORMS_WITH_GROUP_DATA";

// reports mutations
export const SET_REPORT = "SET_REPORT";

// roles mutations
export const SET_ROLES = "SET_ROLES";
export const SET_ROLE = "SET_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

// services mutation
export const SET_SERVICES = "SET_SERVICES";
export const SET_SERVICE = "SET_SERVICE";
export const ADD_SERVICE = "ADD_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const REMOVE_SERVICE = "REMOVE_SERVICE";

// trailers mutations
export const SET_TRAILERS = "SET_TRAILERS";
export const SET_TRAILER = "SET_TRAILER";

// transporters mutations
export const SET_TRANSPORTERS = "SET_TRANSPORTERS";
export const SET_TRANSPORTER = "SET_TRANSPORTER";
export const SET_AVERAGE_BILL_RATE = "SET_AVERAGE_BILL_RATE";

//user mutations
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
