import router from "../../router";

const needGuest = (auth) => auth === "guest";

const GuestAuth = (to, from) => {
  const guest = needGuest(to.meta.requiresAuth);
  const jwt = localStorage.getItem("id_token");
  if (guest && jwt && jwt.length > 0) router.push({ name: to.meta.redirect });
};

export default GuestAuth;
