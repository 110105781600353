import router from "../../router";
import store from '../../store';
import { setToken } from "../../helpers/http";

const needAuth = auth => auth === true;

const AdminAuth = async (to, from) => {
  const auth = to.meta.requiresAuth;
  if (!needAuth(auth)) {
    return;
  }

  const jwt = localStorage.getItem('id_token')

  if(!jwt) {
    return router.push({ name: to.meta.redirect })
  }

  setToken(jwt)

  return

};

export default AdminAuth;
