const state = () => ({
  units: [],
  unit: null,
  filters: {},
  sort: "company.name",
  defaultSort: "company.name",
  order: "asc",
  totalCount: 0,
});

export default state;
