export const CompanyDriversRoutes = [
  {
    path: "drivers",
    name: "Company.drivers.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/CompanyDriversIndex.vue"),
    meta: {
      permissions: ["register:write"],
    },
  },
];
