import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";

const actions = {
  fetchInvoiceRowsWithUnregisteredForm: async (
    { commit, rootGetters },
    { page = 1, limit = 100 } = {}
  ) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/operations/wrongForm?`);

      const months = rootGetters["filters/getMonthsNumbers"];

      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];

      // add date range query params
      url.dateRange();
      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }

      // add order query params
      url.paged(page, limit);

      const res = await http.get(url.get());

      const { rows } = res.data;

      if (rows) {
        commit("SET_CHECK", { key: "invoiceRowsWithUnregisteredForm", check: rows });
      }

      return rows;
    } catch (error) {
      console.warn(error);
      return error.response?.data;
    }
  },

  fetchInvoiceRowsWithoutForm: async ({ commit, rootGetters }, { page = 1, limit = 100 } = {}) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/operations/reclassifiedMatch?`);

      const months = rootGetters["filters/getMonthsNumbers"];

      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];

      // add date range query params
      url.dateRange();
      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }

      // add order query params
      url.paged(page, limit);

      const res = await http.get(url.get());

      const { rows } = res.data;

      if (rows) {
        commit("SET_CHECK", { key: "invoiceRowsWithoutForm", check: rows });
      }

      return rows;
    } catch (error) {
      console.warn(error);
      return error.response?.data;
    }
  },

  fetchFormsWithoutMargin: async ({ commit, rootGetters }, { page = 1, limit = 100 } = {}) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/operations/missingInvoices?`);

      const months = rootGetters["filters/getMonthsNumbers"];

      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];

      // add date range query params
      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }
      url.dateRange();

      // add order query params
      url.paged(page, limit);

      const res = await http.get(url.get());

      const { rows } = res.data;

      if (rows) {
        commit("SET_CHECK", { key: "formsWithoutMargin", check: rows });
      }

      return rows;
    } catch (error) {
      console.warn(error);
      return error.response?.data;
    }
  },

  fetchFormsWithoutService: async ({ commit, rootGetters }, { page = 1, limit = 100 } = {}) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/operations/missingService?`);

      const months = rootGetters["filters/getMonthsNumbers"];

      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];

      // add date range query params
      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }
      url.dateRange();

      // add order query params
      url.paged(page, limit);

      const res = await http.get(url.get());

      const { rows } = res.data;

      if (rows) {
        commit("SET_CHECK", { key: "formsWithoutService", check: rows });
      }

      return rows;
    } catch (error) {
      console.warn(error);
      return error.response?.data;
    }
  },
};

export default actions;
