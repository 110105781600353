const actions = {
  setYear: ({ commit }, year) => {
    commit("SET_YEAR_FILTER", { year });
  },

  setMonthsRange: async ({ commit }, range) => {
    commit("SET_MONTHS_RANGE_FILTER", { range });
  },

  setDay: ({ commit }, day) => {
    commit("SET_DAY_FILTER", { day });
  },
};

export default actions;
