import { CompanyAccountMovementsRoutes } from "./accountMovements";
import { CompanyAuthorizationsRoutes } from "./authorizations";
import { CompanyChecksRoutes } from "./check";
import { CompanyInvoicesRoutes } from "./invoices";
import { CompanyFormsRoutes } from "./forms";
import { CompanyUsersRoutes } from "./users";
import { CompanyAccountsRoutes } from "./accounts";
import { CompanyLocalUnitsRoutes } from "./localUnits";
import { CompanyOptionsRoutes } from "./options";
import { CompanyReportsRoutes } from "./reports";
import { CompanyRegistersRoutes } from "./registers";
import { CompanyServicesRoutes } from "./services";
import { CompanyTrailersRoutes } from "./trailers";
import { CompanyTransportersRoutes } from "./transporters";
import { CompanyDriversRoutes } from "./drivers";
import { CompanyImportsRoutes } from "./imports";

export const AdminCompanyRoutes = [
  {
    path: "companies",
    name: "Admin.companies.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/admin/CompaniesIndex.vue"),
    meta: {
      permissions: ["admin", "companies:read", "companies:write"],
    },
  },
  // {
  //   path: 'companies/create',
  //   name: 'Admin.companies.create',
  //   component: () => import('@/views/admin/CompaniesCreate.vue'),
  //   meta: {
  //     permissions: ['admin','companies:write']
  //   }
  // },
  {
    path: "companies/:companyId",
    name: "Admin.companies.show",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/admin/CompaniesShow.vue"),
    meta: {
      permissions: ["admin", "companies:read", "companies:write"],
    },
  },
];

export const CompanyRoutes = [
  {
    path: "",
    name: "Company.dashboard",
    component: () => import(/* webpackChunkName: "group-company" */ "@/views/CompanyDashboard.vue"),
    meta: {
      permissions: ["admin", "company:admin", "company:dashboard"],
    },
  },
  ...CompanyAccountMovementsRoutes,
  ...CompanyAccountsRoutes,
  ...CompanyAuthorizationsRoutes,
  ...CompanyChecksRoutes,
  ...CompanyDriversRoutes,
  ...CompanyFormsRoutes,
  ...CompanyInvoicesRoutes,
  ...CompanyLocalUnitsRoutes,
  ...CompanyOptionsRoutes,
  ...CompanyRegistersRoutes,
  ...CompanyServicesRoutes,
  ...CompanyTrailersRoutes,
  ...CompanyTransportersRoutes,
  ...CompanyUsersRoutes,
  ...CompanyReportsRoutes,
  ...CompanyImportsRoutes,
];
