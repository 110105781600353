import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/index.css";
import { VueCsvImportPlugin } from "vue-csv-import";

const app = createApp(App).use(VueCsvImportPlugin);

app.directive("guards", {
  beforeMount(el, binding) {
    const user = store.getters["users/getUser"];
    // const permissions = store.getters['options/getDefaultOptionByKey']('permissions')
    const permissions = binding.value;
    let display = false;

    if (!permissions || !permissions.length) {
      display = true;
    } else if (!user.permissions) {
      display = false;
    } else {
      for (const p of permissions) {
        if (user.permissions.includes(p)) display = true;
      }
    }

    if (!display) el.style.display = "none";
  },
});

app.directive("not-guards", {
  beforeMount(el, binding) {
    const user = store.getters["users/getUser"];
    // const permissions = store.getters['options/getDefaultOptionByKey']('permissions')
    const permissions = binding.value;
    let display = false;

    if (!permissions || !permissions.length) {
      display = true;
    } else if (!user.permissions) {
      display = false;
    } else {
      for (const p of permissions) {
        if (user.permissions.includes(p)) display = true;
      }
    }

    display = !display;

    if (!display) el.style.display = "none";
  },
});

app.use(store).use(router).mount("#app");
