export const CompanyServicesRoutes = [
  {
    path: "services",
    name: "Company.services.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/CompanyServiceLinesIndex.vue"),
    meta: {
      permissions: ["lines:read"],
    },
  },
  {
    path: "services/flows",
    name: "Company.serviceFlows.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/CompanyServiceFlowsIndex.vue"),
    meta: {
      permissions: ["flows:read"],
    },
  },
  {
    path: "services/standard",
    name: "Company.standardCosts.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/CompanyStandardCostsIndex.vue"),
    meta: {
      permissions: ["costs:read"],
    },
  },
];
