export const CompanyRegistersRoutes = [
  {
    path: "register",
    name: "Company.registers.index",
    component: () =>
      import(/* webpackChunkName: "group-admin" */ "@/views/CompanyRegistersIndex.vue"),
    meta: {
      permissions: ["register:read"],
    },
  },
];
