import * as TYPES from "../../../store/mutation-types";

const mutations = {
  [TYPES.SET_AUTHORIZATIONS]: (state, { authorizations }) => {
    state.authorizations = authorizations;
  },
  [TYPES.SET_AUTHORIZATION]: (state, { authorization }) => {
    state.authorization = authorization;
  },
  [TYPES.ADD_AUTHORIZATION]: (state, { authorization }) => {
    state.authorizations.push(authorization);
  },
  [TYPES.UPDATE_AUTHORIZATION]: (state, { authorization }) => {
    const a = state.authorizations.find((el) => el._id === authorization._id);
    const i = state.authorizations.indexOf(a);
    // console.log("update auth", i, authorization);
    if (i >= 0) state.authorizations.splice(i, 1, authorization);
    if (state.authorization?._id === authorization._id) {
      state.authorization = authorization;
    }
  },
  [TYPES.REMOVE_AUTHORIZATION]: (state, id) => {
    const a = state.authorizations.find((el) => el._id === id);
    const i = state.authorizations.indexOf(a);
    if (i >= 0) state.authorizations.splice(i, 1);
  },
};

export default mutations;
