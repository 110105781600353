const state = () => ({
  dashboards: {
    main: null,
    broker: null,
    plant: null,
    transporter: null,
    producer: null,
  },
});

export default state;
