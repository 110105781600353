const getters = {
  getRoles: (state) => state.roles,
  getRole: (state) => state.role,
  getScopes: (state) => state.scopes,
  getPermissions: (state) => state.permissions,
  getAllPermissionsKeys: (state) => {
    const keys = [];
    for (const key in state.permissions) {
      keys.push(...state.permissions[key].permissions.map((el) => el.key));
    }
    return keys;
  },
};

export default getters;
