import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_GENERAL_ACCOUNTS]: (state,{ accounts }) => {
    state.accounts = accounts
  },
  [TYPES.SET_GENERAL_ACCOUNT]: (state,{ account }) => {
    state.account = account
  },
  [TYPES.UPDATE_GENERAL_ACCOUNT] : (state,{ account }) => {
    const a = state.accounts.find( el => el._id === account._id )
    const i = state.accounts.indexOf(a)
    if(i >= 0)
      state.accounts.splice(i,1,account)
  },
  [TYPES.ADD_GENERAL_ACCOUNT] : (state,{ account }) => {
    state.accounts.push( account )
  },
  [TYPES.REMOVE_GENERAL_ACCOUNT] : (state,id) => {
    const a = state.accounts.find( el => el._id === id )
    const i = state.accounts.indexOf(a)
    if(i >= 0)
      state.accounts.splice(i,1)
  },
}

export default mutations