import * as TYPES from "../../../store/mutation-types";

const mutations = {
  [TYPES.SET_YEAR_FILTER]: (state, { year }) => {
    state.year = year;
  },
  [TYPES.SET_DAY_FILTER]: (state, { day }) => {
    state.day = day;
  },
  [TYPES.SET_MONTHS_RANGE_FILTER]: (state, { range }) => {
    state.monthsRange[0] = range[0] >= 0 ? range[0] : 0;
    state.monthsRange[1] = range[1] <= 11 ? range[1] : 11;
  },
  [TYPES.SET_AUTHORIZATIONS_FILTER]: (state, { authorizations = [] } = {}) => {
    state.authorizations = authorizations;
  },
  [TYPES.SET_SERVICES_FILTER]: (state, { services = [] } = {}) => {
    state.services = services;
  },
  [TYPES.SET_CER_CODES_FILTER]: (state, { cerCodes = [] } = {}) => {
    state.cerCodes = cerCodes;
  },
  [TYPES.SET_AVAILABLE_CER_CODES]: (state, { availableCerCodes = [] } = {}) => {
    state.availableCerCodes = availableCerCodes;
  },
};

export default mutations;
