import * as TYPES from "../../../store/mutation-types";

const mutations = {
  [TYPES.SET_CHECK]: (state, { check, key }) => {
    state.checks[key] = check;
  },
  [TYPES.CHECKS]: (state, { checks }) => {
    state.checks = checks;
  },
};

export default mutations;
