const state = () => ({
  reports: {
    CE: null,
    producer: null,
    broker: null,
    lines: [],
    quantities: [],
    accounts: [],
  },
  filters: {},
});

export default state;
