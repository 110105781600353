const state = () => ({
  unregistered: [],
  unregisteredCount: 0,
  registers: [],
  group: null,
  selectedForms: [],
  totalCount: 0,
  register: null,
  filters: {},
  sort: "travelInfo.arrival",
  defaultSort: "travelInfo.arrival",
  order: "asc",
});

export default state;
