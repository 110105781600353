export const CompanyOptionsRoutes = [
  {
    path: "options",
    name: "Company.options",
    component: () => import(/* webpackChunkName: "group-options" */ "@/views/CompanyOptions.vue"),

    children: [
      {
        path: "",
        name: "Company.options.general",
        component: () =>
          import(/* webpackChunkName: "group-options" */ "@/views/CompanyOptionsGeneral.vue"),
        meta: {
          permissions: ["company:admin"],
        },
      },
      {
        path: "forms",
        name: "Company.options.forms",
        component: () =>
          import(/* webpackChunkName: "group-options" */ "@/views/CompanyOptionsForms.vue"),
        meta: {
          permissions: ["forms:config"],
        },
      },
      {
        path: "register",
        name: "Company.options.register",
        component: () =>
          import(/* webpackChunkName: "group-options" */ "@/views/CompanyOptionsRegister.vue"),
        meta: {
          permissions: ["register:config"],
        },
      },
      {
        path: "services",
        name: "Company.options.services",
        component: () =>
          import(/* webpackChunkName: "group-options" */ "@/views/CompanyOptionsServices.vue"),
        meta: {
          permissions: ["lines:config", "flows:config", "costs:config"],
        },
      },
      {
        path: "reports",
        name: "Company.options.reports",
        component: () =>
          import(/* webpackChunkName: "group-options" */ "@/views/CompanyOptionsReports.vue"),
        meta: {
          permissions: ["report:config"],
        },
      },
      {
        path: "accounts",
        name: "Company.options.accounts",
        component: () =>
          import(/* webpackChunkName: "group-options" */ "@/views/CompanyOptionsAccounts.vue"),
        meta: {
          permissions: ["accounts:config"],
        },
      },
    ],
  },
];
