import store from "../store";

export const AdminUserRoutes = [
  {
    path: "users",
    name: "Admin.users.index",
    component: () => import(/* webpackChunkName: "group-users" */ "@/views/admin/UsersIndex.vue"),
    meta: {
      permissions: ["admin", "companies:read", "companies:write", "users:create", "users:read"],
    },
  },
  {
    path: "profile",
    component: () => import(/* webpackChunkName: "group-users" */ "@/views/profile/UsersShow.vue"),
    props: true,
    meta: {
      permissions: ["admin", "companies:read", "companies:write", "users:create", "users:read"],
    },
    children: [
      {
        path: "",
        name: "Admin.profile",
        component: () =>
          import(/* webpackChunkName: "group-users" */ "@/views/profile/UsersProfile.vue"),
      },
      {
        path: "account",
        name: "Admin.profile.account",
        component: () =>
          import(/* webpackChunkName: "group-users" */ "@/views/profile/UsersAccount.vue"),
      },
      {
        path: "password",
        name: "Admin.profile.password",
        component: () =>
          import(/* webpackChunkName: "group-users" */ "@/views/profile/UsersPassword.vue"),
      },
    ],
  },
];

export const CompanyUsersRoutes = [
  {
    path: "users",
    name: "Company.users.index",
    component: () => import("@/views/CompanyUsers.vue"),
    meta: {
      requiresAuth: false,
      // permissions: ['admin','companies:read','companies:write','users:create','users:read','company:admin']
    },
  },
  {
    path: "profile",
    component: () => import("@/views/profile/UsersShow.vue"),
    props: true,
    meta: {
      permissions: [...store.getters["roles/getAllPermissionsKeys"]],
    },
    children: [
      {
        path: "",
        name: "Company.profile",
        component: () => import("@/views/profile/UsersProfile.vue"),
      },
      {
        path: "account",
        name: "Company.profile.account",
        component: () => import("@/views/profile/UsersAccount.vue"),
      },
      {
        path: "password",
        name: "Company.profile.password",
        component: () => import("@/views/profile/UsersPassword.vue"),
      },
    ],
  },
];
