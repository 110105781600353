import { http } from "../../../helpers/http";
import router from "../../../router";

const actions = {
  fetchTrailers: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.get(`companies/${companyId}/trailers`);
      // const res = await http.get('transporters?_sort=plate&_order=asc')

      const { trailers } = res.data;

      const trailerWithBillRate = trailers.filter((el) => el.billRate > 0);
      const sum = trailerWithBillRate.reduce((prev, next) => {
        return prev + parseFloat(next.billRate || 0);
      }, 0);

      commit("SET_AVERAGE_BILL_RATE", { average: sum / Math.max(trailerWithBillRate.length, 1) });
      commit("SET_TRAILERS", { trailers });
      return trailers;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  updateTrailer: async ({ dispatch }, newTrailer) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.patch(`companies/${companyId}/trailers/${newTrailer._id}`, newTrailer);
      const { trailer } = res.data;

      await dispatch("fetchTrailers"); //or replace with commit(ADD_TRAILER) and sort

      return trailer;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  createTrailer: async ({ dispatch, commit }, newTrailer) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const res = await http.post(`companies/${companyId}/trailers`, newTrailer);
      const { trailer } = res.data;

      await dispatch("fetchTrailers"); //or replace with commit(ADD_TRANSPORTER) and sort
      commit("SET_TRAILER", { trailer });
      return trailer;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  deleteTrailer: async ({ dispatch }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const { data } = await http.delete(`companies/${companyId}/trailers/${id}`);

      await dispatch("fetchTrailers");

      return data;
    } catch (error) {
      console.warn(error);
      return error.response.data;
    }
  },

  syncWithForms: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.post(`companies/${companyId}/trailers/sync`);

      const { trailers, count } = res.data;

      const sum = trailers.reduce((prev, next) => {
        return prev + parseFloat(next.billRate || 0);
      }, 0);

      commit("SET_AVERAGE_BILL_RATE", { average: sum / trailers.length });
      commit("SET_TRAILERS", { trailers });

      return { trailers, count };
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  migrate: async ({ dispatch }, { to, from }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.post(`companies/${companyId}/trailers/migrate`, { to, from });

      const { modified } = res.data;

      if (modified >= 0) {
        await dispatch("deleteTrailer", from);
      }

      return modified;
    } catch (error) {
      console.warn(error);
      return error.response?.data;
    }
  },
};

export default actions;
