import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";

const actions = {
  fetchLocalUnits: async ({ commit, rootGetters }, { page, limit } = {}) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/local-units?`);

      const sort = rootGetters["getSort"]("localUnits");
      const order = rootGetters["getOrder"]("localUnits");

      const filters = rootGetters["getFilters"]("localUnits");

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ");
          let operator = "";

          if (value.length > 1) {
            switch (value[0]) {
              case "<":
                operator = "lte";
                break;
              case ">":
                operator = "gte";
                break;
            }
          }

          url.addQuery(key, operator, value[1] ?? value[0]);
        }
      }

      url.sort(sort, order).paged(page, limit);

      const res = await http.get(url.get());
      const { units, total = 0 } = res.data;

      if (units) {
        commit("SET_LOCAL_UNITS", { units });
        commit("setTotalCount", { totalCount: total, module: "localUnits" }, { root: true });

        return units;
      } else {
        throw "Error fetching local units";
      }
    } catch (error) {
      console.warn(error);
      return error?.response?.data;
    }
  },

  fetchLocalUnit: async ({ commit }, id) => {
    //   TODO completare
    try {
      const res = await http.get(`localUnits/${id}`);
      const unit = res.data;

      commit("SET_LOCAL_UNIT", { unit });

      return unit;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  setRelevant: async ({ dispatch }, unit) => {
    // console.log(unit);
    // return dispatch("updateUnit", unit);
  },

  updateUnit: async ({ commit }, newUnit) => {
    // TODO complete
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/local-units/${newUnit._id}`);

      const res = await http.put(url.get(), newUnit);

      const { toUpdate: unit } = res.data;

      commit("UPDATE_LOCAL_UNIT", { unit });
      return unit;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  migrate: async ({ dispatch }, { code, localUnitCode, to, id }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/local-units/migrate`);

      const res = await http.post(url.get(), { code, localUnitCode, to });

      const { modified } = res.data;

      if (modified >= 0) {
        await dispatch("deleteUnit", id);
      }

      return modified;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  deleteUnit: async ({ commit }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.delete(`companies/${companyId}/local-units/${id}`);

      commit("REMOVE_LOCAL_UNIT", id);

      return res;
    } catch (e) {
      return e.response.data;
    }
  },
};

export default actions;
