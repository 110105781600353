import store from "../store";
import { useDateble } from "../hooks/useDateble";
const { date } = useDateble();
import { DateTime } from "luxon";

export default class Url {
  url = "";

  constructor(baseUrl, options = {}) {
    this.url = baseUrl;
  }

  addQuery(key, operator, value, prefix = "&") {
    this.url += prefix ?? "";
    this.url += key ?? "";
    this.url += operator ?? `_${operator}`;
    this.url += "=";
    this.url += value ?? "";
    return this;
  }

  sort(orderBy = "date", order = "asc", prefix = "&") {
    this.url += `${prefix}_sort=${orderBy}&_order=${order}&sort=${orderBy}&dir=${
      order == "asc" ? 1 : -1
    }`;
    return this;
  }

  paged(page = 1, limit = 100, prefix = "&") {
    this.url += `${prefix}_page=${page}&_limit=${limit}`;
    this.url += `&skip=${(page - 1) * limit}&limit=${limit}`;
    return this;
  }

  get() {
    return this.url;
  }

  dateRange(i) {
    const months = store.getters["filters/getMonthsNumbers"];
    const year = store.getters["filters/getYear"] || new Date().getFullYear();
    const monthsRange = store.getters["filters/getMonthsRange"];
    let day = store.getters["filters/getDay"];

    if (day && day < 10) {
      day = "0" + day;
    }

    // console.log(day);

    if (monthsRange["0"] >= 0) {
      this.addQuery(
        "fromDate",
        "",
        date(`${year}-${months[monthsRange[0]]}-${day || "01"}`)
          .toJSDate()
          .toISOString()
      );
    }

    if (monthsRange["1"] >= 0) {
      if (day !== null) {
        // console.log("ulr date range", day);
        const endOfDay = DateTime.fromISO(`${year}-${months[monthsRange[1]]}-${day || "01"}`)
          .endOf("day")
          .toJSDate()
          .toISOString();
        this.addQuery("toDate", "", endOfDay);
      } else {
        this.addQuery(
          "toDate",
          "",
          date(`${year}-${months[monthsRange[1]]}-${day || "01"}`, true)
            .toJSDate()
            .toISOString()
        );
      }
    }
  }
}
