import { http } from "../../../helpers/http";
import router from "../../../router";

const actions = {
  fetchOptions: async ({ commit }) => {
    const companyId = router.currentRoute.value.params.companyId;

    const res = await http.get(`options`);

    const { options } = res.data;

    if (options) {
      commit("SET_DEFAULT_OPTIONS", { options });
    }
  },

  fetchCompanyOptions: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const res = await http.get(`companies/${companyId}/options`);

      const { options } = res.data;

      if (options) {
        commit("SET_OPTIONS", { options });
        return options;
      }
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  create: async ({ commit }, { key, value, category }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const res = await http.post(`companies/${companyId}/options`, { key, value, category });

      const { option } = res.data;

      if (option) {
        commit("ADD_OPTION", { option });
        return option;
      }
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  update: async ({ commit }, { key, value, category, _id }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const res = await http.post(`companies/${companyId}/options/${_id}`, {
        key,
        value,
        category,
      });

      const { option } = res.data;

      if (option) {
        commit("UPDATE_OPTION", { option });
        return option;
      }
    } catch (error) {
      console.warn(error);
      return error;
    }
  },
};

export default actions;
