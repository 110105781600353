import { http } from "../../../helpers/http";
import router from "../../../router";
import Url from "../../../helpers/url";

const actions = {
  fetchForms: async ({ commit, rootGetters }, { limit = 100, page } = {}) => {
    try {
      const months = rootGetters["filters/getMonthsNumbers"];
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/forms?`);

      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      // url += `&date_like=${year}`

      const weightFormat = rootGetters["options/getOptionByKey"]("weight");

      // const monthsRange = rootGetters["filters/getMonthsRange"];

      const filters = rootGetters["getFilters"]("forms");

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ").join();
          const [first, ...rest] = value;
          let operator = "";

          if (value.length > 1) {
            switch (first) {
              case "<":
                operator = "<";
                break;
              case ">":
                operator = ">";
                break;
            }
          }

          let val = operator ? rest.join() : value;

          if (key === "weight") {
            val = parseFloat(operator ? rest.join() : value);
            val /= weightFormat?.value?.value ?? 1;
          }

          url.addQuery(key, "", operator + val);
        }
      }

      url.dateRange();

      // if (monthsRange["0"] >= 0) {
      //   // url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      //   url.dateRange(0);
      // }

      // if (monthsRange["1"] >= 0) {
      //   // url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-01`);
      //   url.dateRange(1);
      // }

      const sort = rootGetters["getSort"]("forms");
      const order = rootGetters["getOrder"]("forms");

      url.sort(sort, order).paged(page, limit);

      const res = await http.get(url.get());

      const { forms, total = 0 } = res.data;

      commit("SET_FORMS", { forms });
      commit("setTotalCount", { totalCount: total, module: "forms" }, { root: true });
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  fetchForm: async ({ commit }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/forms/${id}`);

      const res = await http.get(url.get());

      const { form } = res.data;
      if (form._id) {
        commit("SET_FORM", { form });
        return form;
      } else {
        throw "Error fetching form";
      }
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  updateForm: async ({ commit }, newForm) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/forms/${newForm._id}`);

      const res = await http.patch(url.get().newForm);

      const { form } = res.data;

      if (form) {
        commit("SET_FORM", { form: newForm });
        return res.data;
      }
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  fetchGroupByFormId: async (_, { formId = null } = {}) => {
    if (!formId) return;
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/forms/${formId}/group`);

      const res = await http.get(url.get());

      const { members } = res.data;
      return members;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },
};

export default actions;
