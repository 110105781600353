<template>
  <component v-if="layout" :is="layout">
    <slot />
  </component>
</template>

<script>

import AppLayoutDefault from './AppLayoutBlank.vue'
import { shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'AppLayout',
  setup() {
    const layout = shallowRef(AppLayoutDefault) //replaced from  markRaw
    const route = useRoute()
    watch(
      () => route.meta,
      async meta => {
        try {
          /* eslint-disable */
          if(meta.layout) {
            const component = await import(`@/layouts/${meta.layout}.vue`)
            layout.value = component?.default || AppLayoutDefault
          }
        } catch (e) {
          console.warn('error', e)
          layout.value = AppLayoutDefault
        }
      },
      { immediate: true },
    )
    return { layout }
  },
}

//this work with warnign
// import AppLayoutDefault from './AppLayoutDefault'
// export default {
//   name: "AppLayout",
//   data: () => ({
//     layout: AppLayoutDefault
//   }),
//   watch: {
//     $route: {
//       immediate: true,
//       async handler(route) {
//         try {
//           const component = await import(`@/layouts/${route.meta.layout}.vue`)
//           this.layout = component?.default || AppLayoutDefault
//         } catch (e) {
//           this.layout = AppLayoutDefault
//         }
//       }
//     }
//   }
// }

</script>
