const state = () => ({
  movements: [],
  totalCount: 0,
  movement: null,
  sort: "date",
  defaultSort: "date",
  order: "asc",
  filters: {},
});

export default state;
