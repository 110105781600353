import { v4 as uuidv4 } from "uuid";

const actions = {
  createNotification: ({ commit }, { text, status = "info" }) => {
    const notification = {
      text,
      status,
      id: uuidv4(),
    };

    commit("ADD_NOTIFICATION", { notification });
  },
};

export default actions;
