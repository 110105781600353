import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_USER] : (state,{user}) => {
    state.user = user
  },
  [TYPES.SET_USERS] : (state, {users}) => {
    state.users = users
  },
  [TYPES.ADD_USER] : (state, {user}) => {
    state.users.push(user)
  },
  [TYPES.UPDATE_USER] : (state, {user}) => {
    const u = state.users.find(el => el._id === user._id)
    const index = state.users.indexOf(u)

    if(u && index >= 0) {
      state.users.splice(index,1,user)
    }
  },
  [TYPES.REMOVE_USER]: (state,{user}) => {
    const u = state.users.find(el => el._id === user._id)
    const index = state.users.indexOf(u)

    if(u && index >= 0) {
      state.users.splice(index,1)
    }
  }
}

export default mutations