// import dayjs from "dayjs";
// import * as it from "dayjs/locale/it";
import { useStore } from "vuex";
import { computed } from "vue";
import { DateTime } from "luxon";

export function useDateble(locale = "it") {
  // dayjs.locale('it')

  const store = useStore();
  const dateFormat = computed(() => store.getters["options/getOptionByKey"]("date"));

  const dateToFormat = (date, format = null) => {
    if (!date) {
      return date;
    }

    try {
      let stringFormat = format ?? dateFormat.value?.value?.value ?? "DD/MM/YYYY";
      // dayjs.locale("it");
      // return dayjs(date).format(stringFormat);
      stringFormat = stringFormat.replaceAll("Y", "y");
      stringFormat = stringFormat.replaceAll("D", "d");
      stringFormat = stringFormat.replaceAll("M", "L");
      const parsedDate = DateTime.fromISO(date);
      return parsedDate.toFormat(stringFormat);
    } catch (error) {
      console.error(error);
      return date;
    }
  };

  const date = (dateString = "", endOfMonth = false) => {
    const [year, month, day] = dateString.split("-");
    let date = DateTime.fromISO(dateString);

    if (endOfMonth) {
      date = date.endOf("month");
    }

    return date;
  };

  return {
    dateToFormat,
    date,
  };
}
