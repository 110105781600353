import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_ROLES] : (state,{roles}) => {
    state.roles = roles
  },
  [TYPES.SET_ROLE] : (state,{ role }) => {
    state.role = role
  },
  [TYPES.UPDATE_ROLE] : (state, {newRole}) => {
    let role = state.roles.find( r => r._id === newRole._id )
    const i = state.roles.indexOf(role)
    if( i >= 0)
      state.roles.splice(i,1,newRole)
  },
  [TYPES.ADD_ROLE] : (state,{newRole}) => {
    state.roles.push(newRole)
  },
  [TYPES.DELETE_ROLE] : (state, { id }) => {
    let role = state.roles.find( r => r._id === id )
    const i = state.roles.indexOf(role)
    if( i >= 0)
      state.roles.splice(i,1) 
  }
}

export default mutations