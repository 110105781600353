import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";
import { useDateble } from "../../../hooks/useDateble";

const { date } = useDateble();

const actions = {
  fetchReport: async ({ dispatch }, { actionName }) => {
    return dispatch(actionName);
  },

  fetchReportCE: async ({ rootGetters, commit }) => {
    try {
      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];
      const months = rootGetters["filters/getMonthsNumbers"];
      const companyId = router.currentRoute.value.params.companyId;

      const url = new Url(`companies/${companyId}/reports/economic?`);

      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }
      url.dateRange();

      let res = await http.get(url.get());

      let { report } = res.data;

      commit("SET_REPORT", { report, key: "CE" });
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  fetchReportProducer: async ({ rootGetters, commit }, { page = 1, limit, groupFilter } = {}) => {
    try {
      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];
      const months = rootGetters["filters/getMonthsNumbers"];
      const companyId = router.currentRoute.value.params.companyId;

      const url = new Url(`companies/${companyId}/reports/producer?`);
      const filters = rootGetters["getFilters"]("reports");

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ");
          let operator = "";

          if (value.length > 1) {
            switch (value[0]) {
              case "<":
                operator = "lte";
                break;
              case ">":
                operator = "gte";
                break;
            }
          }

          url.addQuery(key, "", value.join(""));
        }
      }

      url.dateRange();
      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }

      url.paged(page, limit);

      let res = await http.get(url.get(), {
        params: groupFilter || {},
      });

      let { report, total = 0 } = res.data;

      if (!groupFilter) {
        commit("SET_REPORT", { report, key: "producer" });
        commit("setTotalCount", { totalCount: total, module: "reports" }, { root: true });
      }

      return report;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  fetchReportBroker: async ({ rootGetters, commit }, { page = 1, limit, groupFilter } = {}) => {
    try {
      // const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      // const companyId = router.currentRoute.value.params.companyId;

      // let res = await http.get(`companies/${companyId}/reports/broker`, {
      //   params: {
      //     year: year,
      //   },
      // });

      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];
      const months = rootGetters["filters/getMonthsNumbers"];
      const companyId = router.currentRoute.value.params.companyId;

      const url = new Url(`companies/${companyId}/reports/broker?`);
      const filters = rootGetters["getFilters"]("reports");

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ");
          let operator = "";

          if (value.length > 1) {
            switch (value[0]) {
              case "<":
                operator = "lte";
                break;
              case ">":
                operator = "gte";
                break;
            }
          }

          url.addQuery(key, "", value.join(""));
        }
      }

      url.dateRange();
      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }

      url.paged(page, limit);

      let res = await http.get(url.get(), {
        params: groupFilter || {},
      });

      let { report, total = 0 } = res.data;

      if (!groupFilter) {
        commit("SET_REPORT", { report, key: "broker" });
        commit("setTotalCount", { totalCount: total, module: "reports" }, { root: true });
      }

      return report;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  fetchReportServiceLine: async ({ commit, rootGetters }, { authorization, line }) => {
    try {
      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];
      const months = rootGetters["filters/getMonthsNumbers"];
      const companyId = router.currentRoute.value.params.companyId;

      const url = new Url(`companies/${companyId}/reports/line/${line}?`);
      url.addQuery("authorization", "", authorization._id);

      url.dateRange();
      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }

      let res = await http.get(url.get());

      let { report } = res.data;

      const startDateString = `${year}-${months[monthsRange[0]]}-01`;
      const endDateString = `${year}-${months[monthsRange[1]]}-01`;

      commit("SET_REPORT", {
        report: {
          line,
          authorization: authorization._id,
          data: report,
          // range: `${year}-${months[monthsRange[0]]}-01 / ${year}-${months[monthsRange[1]]}-31`,
          range: [date(startDateString), date(endDateString, true)],
        },
        key: "lines",
      });

      return report;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  fetchReportQuantities: async (
    { commit, rootGetters },
    { authorization, intercompany = true, dateField = "travelInfo.arrival" }
  ) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      const url = new Url(`companies/${companyId}/reports/quantities/${authorization._id}?`);
      url.addQuery("intercompany", "", intercompany);
      url.addQuery("dateField", "", dateField);

      const filters = rootGetters["getFilters"]("reports");

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ").join();
          const [first, ...rest] = value;
          let operator = "";

          if (value.length > 1) {
            switch (first) {
              case "<":
                operator = "<";
                break;
              case ">":
                operator = ">";
                break;
            }
          }

          let val = operator ? rest.join() : value;

          url.addQuery(key, "", operator + val);
        }
      }

      url.dateRange();

      let res = await http.get(url.get());

      let { report } = res.data;

      // console.log(report);

      commit("SET_REPORT", {
        report,
        key: "quantities",
      });

      return report;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  fetchReportAccounts: async ({ commit, rootGetters }, { type = "all" }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      const url = new Url(`companies/${companyId}/reports/reclassified/?`);
      url.addQuery("economic", "", type);

      const filters = rootGetters["getFilters"]("reports");

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ").join();
          const [first, ...rest] = value;
          let operator = "";

          if (value.length > 1) {
            switch (first) {
              case "<":
                operator = "<";
                break;
              case ">":
                operator = ">";
                break;
            }
          }

          let val = operator ? rest.join() : value;

          url.addQuery(key, "", operator + val);
        }
      }

      url.dateRange();

      let res = await http.get(url.get());

      let { report } = res.data;

      commit("SET_REPORT", {
        report,
        key: "accounts",
      });

      return report;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },
};

export default actions;
