import * as TYPES from "../../../store/mutation-types";

const mutations = {
  [TYPES.SET_FORMS]: (state, { forms }) => {
    state.forms = forms;
  },
  [TYPES.SET_FORM]: (state, { form }) => {
    state.form = form;
  },
  [TYPES.UPDATE_FORM]: (state, { form }) => {
    const a = state.forms.find((el) => el._id === form._id);
    const i = state.forms.indexOf(a);
    if (i >= 0) state.forms.splice(i, 1, form);
  },
  [TYPES.SET_ANALYTICAL_DATA]: (state, { analyticalData }) => {
    state.analyticalData = analyticalData;
  },
  [TYPES.SET_PER_TONS]: (state, { perTons }) => {
    state.perTons = perTons;
  },
  [TYPES.SET_SHOW_STANDARD]: (state, { showStandard }) => {
    state.showStandard = showStandard;
  },
  [TYPES.TOGGLE_CATEGORY_COLUMN]: (state, i) => {
    state.columnsToggle.categories[i].open = !state.columnsToggle.categories[i].open;
  },
};

export default mutations;
