import { createStore } from "vuex";
import router from "../router";
import { http } from "../helpers/http";
import { cerExists, cerList, findCerByCode, checkCerFormat, cerHierarchy } from "./cer.example";

import accountMovements from "../modules/accountMovements/store";
import authorizations from "../modules/authorizations/store";
import checks from "../modules/checks/store";
import companies from "../modules/companies/store";
import dashboards from "../modules/dashboards/store";
import drivers from "../modules/drivers/store";
import filters from "../modules/resource-global-filters/store";
import forms from "../modules/forms/store";
import generalAccounts from "../modules/general-accounts/store";
import invoices from "../modules/invoices/store";
import imports from "../modules/imports/store";
import passiveInvoices from "../modules/invoices/store";
import localUnits from "../modules/local-units/store";
import notifications from "../modules/notifications/store";
import roles from "../modules/roles/store";
import options from "../modules/options/store";
import reclassifiedAccounts from "../modules/reclassified-accounts/store";
import registers from "../modules/registers/store";
import reports from "../modules/reports/store";
import services from "../modules/services/store";
import transporters from "../modules/transporters/store";
import trailers from "../modules/trailers/store";
import users from "../modules/users/store";

export default createStore({
  state: {
    topbar: {
      isReady: false,
    },
    bottombar: {
      isReady: false,
    },
    routes: {
      dashboard: "",
      login: "",
      profile: "",
    },
    cerList,
    cerHierarchy,
  },
  getters: {
    cerExists: () => (cerCode) => cerExists(cerCode),
    getCerByCode: () => (cerCode) => findCerByCode(cerCode),
    checkCerFormat: () => (cerCode) => checkCerFormat(cerCode),
    getCerList: (state) => state.cerList,
    getTopbar: (state) => state.topbar,
    getBottombar: (state) => state.bottombar,
    getRouteByKey:
      (state) =>
      (key = "") =>
        key && state.routes[key],
    getFilters: (state) => (module) => {
      if (module) return state[module].filters;

      return {};
    },
    getFilterByKey:
      (state) =>
      ({ key, module }) => {
        if (module) return state[module].filters[key];

        return null;
      },
    getOrder: (state) => (module) => {
      if (module) return state[module].order;

      return null;
    },
    getSort: (state) => (module) => {
      if (module) return state[module].sort;

      return null;
    },
    getTotalCount: (state) => (module) => {
      if (module) return state[module].totalCount;

      return null;
    },
  },
  mutations: {
    setTopbarStatus: (state, status) => {
      state.topbar.isReady = status;
    },
    setBottombarStatus: (state, status) => {
      state.bottombar.isReady = status;
    },
    setRoute: (state, { key, value }) => {
      if (!state.routes[key]) state.routes[key] = value;
    },
    setFilter: (state, { key, value, module }) => {
      if (!module) return;

      const filters = {};
      filters[key] = value;
      state[module].filters = Object.assign(state[module].filters, filters);
    },
    clearFilter: (state, { module }) => {
      state[module].filters = Object.assign({});
    },
    setSortOrder: (state, { sort, order, module }) => {
      if (!module) return;

      state[module].sort = order
        ? sort
        : state[module].defaultSort
        ? state[module].defaultSort
        : "date";
      state[module].order = order || "asc";
    },
    setTotalCount: (state, { totalCount, module }) => {
      if (!module) return;

      state[module].totalCount = totalCount;
    },
  },
  actions: {
    setTopbarStatus: ({ commit }, status) => {
      commit("setTopbarStatus", status);
    },
    setBottombarStatus: ({ commit }, status) => {
      commit("setBottombarStatus", status);
    },
    setRoute: ({ commit }, { key, value }) => {
      commit("setRoute", { key, value });
    },
    setAdminRoutes: ({ dispatch }) => {
      dispatch("setRoute", { key: "login", value: "/admin/login" });
      dispatch("setRoute", { key: "dashboard", value: "/admin" });
      dispatch("setRoute", { key: "profile", value: "/admin/profile" });
    },
    setCompanyRoutes: ({ dispatch }, { id, slug }) => {
      dispatch("setRoute", { key: "login", value: `/company/${slug}/login` });
      dispatch("setRoute", { key: "dashboard", value: `/company/${id}` });
      dispatch("setRoute", { key: "profile", value: `/company/${id}/profile` });
    },
    setFilter: ({ commit }, { key, value, module }) => {
      commit("setFilter", { key, value, module });
    },
    setSortOrder: ({ commit }, { sort, order, module }) => {
      commit("setOrder", { sort, order, module });
    },
    fetchItems: async ({}, { items, key, val }) => {
      const companyId = router.currentRoute.value.params.companyId;

      const res = await http.get(`companies/${companyId}/${items}?${key}=${val}`);

      return res.data;
    },
  },
  modules: {
    accountMovements,
    authorizations,
    checks,
    companies,
    dashboards,
    drivers,
    filters,
    forms,
    generalAccounts,
    invoices, //active
    imports,
    passiveInvoices,
    localUnits,
    notifications,
    options,
    reclassifiedAccounts,
    registers,
    reports,
    roles,
    services,
    trailers,
    transporters,
    users,
  },
});
