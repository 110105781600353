export const CompanyReportsRoutes = [
  {
    path: "reports/ce",
    name: "Company.reports.ce",
    component: () => import(/* webpackChunkName: "group-reports" */ "@/views/CompanyReportCE"),
    meta: {
      permissions: ["report:ce"],
    },
  },
  {
    path: "reports/producer",
    name: "Company.reports.producer",
    component: () =>
      import(/* webpackChunkName: "group-reports" */ "@/views/CompanyReportProducer"),
    meta: {
      permissions: ["report:producer"],
    },
  },
  {
    path: "reports/broker",
    name: "Company.reports.broker",
    component: () => import(/* webpackChunkName: "group-reports" */ "@/views/CompanyReportBroker"),
    meta: {
      permissions: ["report:broker"],
    },
  },
  {
    path: "reports/line",
    name: "Company.reports.line",
    component: () => import(/* webpackChunkName: "group-reports" */ "@/views/CompanyReportLine"),
    meta: {
      permissions: ["report:lines"],
    },
  },
  {
    path: "reports/quantities",
    name: "Company.reports.quantities",
    component: () =>
      import(/* webpackChunkName: "group-reports" */ "@/views/CompanyReportQuantity"),
    meta: {
      permissions: ["report:quantities"],
    },
  },
  {
    path: "reports/accounts",
    name: "Company.reports.accounts",
    component: () =>
      import(/* webpackChunkName: "group-reports" */ "@/views/CompanyReportAccounts"),
    meta: {
      permissions: ["report:accounts"],
    },
  },
];
