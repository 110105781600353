export const CompanyTrailersRoutes = [
  {
    path: "trailers",
    name: "Company.trailers.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/CompanyTrailersIndex.vue"),
    meta: {
      permissions: ["register:write"],
    },
  },
];
