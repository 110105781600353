import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";

const actions = {
  fetchMovements: async ({ commit, rootGetters }, { page = 1, limit = 100 } = {}) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/accounts/movements?`);

      const months = rootGetters["filters/getMonthsNumbers"];

      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];

      const sort = rootGetters["getSort"]("accountMovements");
      const order = rootGetters["getOrder"]("accountMovements");

      // apply filters
      const filters = rootGetters["getFilters"]("accountMovements");

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ");
          let operator = "";

          if (value.length > 1) {
            switch (value[0]) {
              case "<":
                operator = "lte";
                break;
              case ">":
                operator = "gte";
                break;
            }
          }

          url.addQuery(key, "", value.join(""));
        }
      }

      url.dateRange();
      // add date range query params
      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }

      // add order query params
      url.sort(sort, order).paged(page, limit);

      const res = await http.get(url.get());

      const { movements, total = 0 } = res.data;

      commit("SET_MOVEMENTS", { movements });
      commit("setTotalCount", { totalCount: total, module: "accountMovements" }, { root: true });
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  // TODO show
  // fetchInvoice: async ({ commit },id) => {

  //     try {

  //         const companyId = router.currentRoute.value.params.companyId;
  //         const url = new Url(`companies/${companyId}/invoices/${id}`);
  //         const res = await http.get( url.get() )

  //         const { invoice } = res.data
  //         commit('SET_INVOICE',{ invoice })

  //     } catch (e) {

  //     }
  // },

  // TODO store

  // TODO update

  // TODO delete
};

export default actions;
