import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";

const actions = {
  fetchServices: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.get(`companies/${companyId}/services`);

      let { services } = res.data;

      commit("SET_SERVICES", { services });
    } catch (error) {
      return error;
    }
  },

  createService: async ({ commit }, newService) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.post(`companies/${companyId}/services`, newService);
      const { service } = res.data;

      if (service) {
        commit("ADD_SERVICE", { service });
        return service;
      } else {
        return res;
      }
    } catch (e) {
      return e.response.data;
    }
  },

  updateService: async ({ commit }, newService) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.patch(`companies/${companyId}/services/${newService._id}`, newService);
      const { service } = res.data;

      if (service) {
        commit("UPDATE_SERVICE", { service });
        return service;
      } else {
        return res;
      }
    } catch (e) {
      return e.response?.data;
    }
  },

  deleteService: async ({ commit }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      http
        .delete(`companies/${companyId}/services/${id}`)
        .then((res) => {
          commit("REMOVE_SERVICE", id);
        })
        .catch((err) => {
          return err;
        });
    } catch (error) {
      return e.response?.data;
    }
  },

  sync: async ({ rootGetters }) => {
    try {
      const months = rootGetters["filters/getMonthsNumbers"];
      const companyId = router.currentRoute.value.params.companyId;
      let url = new Url(`companies/${companyId}/services/sync?`);
      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];

      url.dateRange();
      // if(monthsRange['0'] >= 0) {
      //     url.addQuery('fromDate','',`${year}-${months[monthsRange[0]]}-01`)
      // }

      // if(monthsRange['1'] >= 0 ) {
      //     url.addQuery('toDate','',`${year}-${months[monthsRange[1]]}-31`)
      // }

      if (
        !confirm(
          `Vuoi applicare questi flussi a tutti i formulari dal ${year}-${
            months[monthsRange[0]]
          }-01 al ${year}-${months[monthsRange[1]]}-31? La procedura può richiedere alcuni minuti.`
        )
      ) {
        return;
      }

      http
        .get(url.get())
        .then((res) => {
          // commit('REMOVE_SERVICE',id);
          // console.log("sync complete");
        })
        .catch((err) => {
          return err;
        });
    } catch (e) {
      return e.response?.data;
    }
  },
};

export default actions;
