export const CompanyAccountMovementsRoutes = [
  {
    path: "accounts/movements",
    name: "Company.accountMovements.index",
    component: () => import("@/views/CompanyAccountMovementsIndex.vue"),
    meta: {
      permissions: ["accounts:read"],
    },
  },
];
