import * as TYPES from "../../../store/mutation-types";

const mutations = {
  [TYPES.SET_RECLASSIFIED_ACCOUNTS]: (state, { accounts }) => {
    state.accounts = accounts;
  },
  [TYPES.SET_RECLASSIFIED_ACCOUNT]: (state, { account }) => {
    state.account = account;
  },
  [TYPES.ADD_RECLASSIFIED_ACCOUNT]: (state, { account }) => {
    state.accounts.push(account);
  },
  [TYPES.UPDATE_RECLASSIFIED_ACCOUNT]: (state, { account }) => {
    const a = state.accounts.find((el) => el._id === account._id);
    const i = state.accounts.indexOf(a);
    if (account.internalLogistics) {
      const prev = state.accounts.find((el) => el.internalLogistics === true);

      prev && (prev.internalLogistics = false);
    }

    if (i >= 0) state.accounts.splice(i, 1, account);
  },
  [TYPES.REMOVE_ACCOUNT]: (state, id) => {
    const n = state.accounts.find((el) => el._id === id);
    const i = state.accounts.indexOf(n);
    if (i >= 0) state.accounts.splice(i, 1);
  },
  [TYPES.SET_RECLASSIFIED_ACCOUNT_TYPES]: (state, { types }) => {
    state.types = types;
  },
  [TYPES.SET_RECLASSIFIED_ACCOUNT_CATEGORIES]: (state, { categories }) => {
    state.categories = categories;
  },
  [TYPES.UPDATE_RECLASSIFIED_ACCOUNT_CATEGORY]: (state, { category }) => {
    const cat = state.categories.find((el) => el._id === category._id);
    if (!cat) return;
    cat.name = category.name;
  },
  [TYPES.SET_RECLASSIFIED_ACCOUNT_NATURES]: (state, { natures }) => {
    state.natures = natures;
  },
  [TYPES.ADD_RECLASSIFIED_ACCOUNT_NATURE]: (state, { nature }) => {
    state.natures.push(nature);
  },
  [TYPES.UPDATE_RECLASSIFIED_ACCOUNT_NATURE]: (state, { nature }) => {
    const nat = state.natures.find((el) => el._id === nature._id);
    if (!nat) return;
    nat.name = nature.name;
    nat.category = nature.category;
    nat.accountCategoryId = nature.accountCategoryId;
    nat.code = nature.code;
  },
  [TYPES.REMOVE_NATURE]: (state, id) => {
    const n = state.natures.find((el) => el._id === id);
    const i = state.natures.indexOf(n);
    if (i >= 0) state.natures.splice(i, 1);
  },
};

export default mutations;
