const state = () => ({
  forms: [],
  totalCount: 0,
  form: null,
  filters: {},
  sort: "travelInfo.arrival",
  defaultSort: "travelInfo.arrival",
  order: "asc",
  perTons: false,
  analyticalData: false,
  showStandard: false,
  columnsToggle: {
    formCode: { hidden: false },
    cerCode: { hidden: false },
    categories: [
      { open: false, hidden: false },
      { open: false, hidden: false },
      { open: false, hidden: false },
      { open: false, hidden: false },
    ],
  },
});

export default state;
