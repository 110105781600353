export const AdminAccountsRoutes = [
  {
    path: "reclassified/accounts",
    name: "Admin.reclassifiedAccounts.index",
    component: () =>
      import(/* webpackChunkName: "group-account" */ "@/views/admin/ReclassifiedAccountsIndex.vue"),
    meta: {
      permissions: ["admin"],
    },
  },
  {
    path: "reclassified/accounts/categories",
    name: "Admin.reclassifiedAccounts.categories",
    component: () =>
      import(
        /* webpackChunkName: "group-account" */ "@/views/admin/ReclassifiedAccountsCategories.vue"
      ),
    meta: {
      permissions: ["admin"],
    },
  },
];

export const CompanyAccountsRoutes = [
  {
    path: "accounts/general",
    name: "Company.generalAccounts.index",
    component: () =>
      import(/* webpackChunkName: "group-account" */ "@/views/CompanyGeneralAccountsIndex.vue"),
    meta: {
      permissions: ["accounts:read"],
    },
  },
  {
    path: "accounts/reclassified",
    name: "Company.reclassifiedAccounts.index",
    component: () =>
      import(
        /* webpackChunkName: "group-account" */ "@/views/CompanyReclassifiedAccountsIndex.vue"
      ),
    meta: {
      permissions: ["reclassified:read"],
    },
  },
  {
    path: "accounts/reclassified/natures",
    name: "Company.reclassifiedAccounts.natures.index",
    component: () =>
      import(
        /* webpackChunkName: ""group-account */ "@/views/CompanyReclassifiedAccountNaturesIndex.vue"
      ),
    meta: {
      permissions: ["reclassified:read"],
    },
  },
  // {
  //   path: "accounts/reclassified/categories",
  //   name: "Company.reclassifiedAccounts.categories.index",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: ""group-account */ "@/views/CompanyReclassifiedAccountCategoriesIndex.vue"
  //     ),
  //   meta: {
  //     permissions: ["reclassified:read"],
  //   },
  // },
];
