const getters = {
  getAccounts: (state) => state.accounts,
  getAccount: (state) => state.account,
  getAccountById: (state) => (id) => state.accounts.find((el) => el._id === id),
  getLogisticsAccount: (state) => state.accounts.find((el) => el.internalLogistics === true),
  getTypes: (state) => state.types,
  getCategories: (state) => state.categories,
  getNatures: (state) => state.natures,
};

export default getters;
