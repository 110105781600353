const getters = {
  getYear: (state) => state.year,
  getDay: (state) => state.day,
  getMonthsRange: (state) => state.monthsRange,
  getMonths: (state) => state.months.labels,
  getMonthsNumbers: (state) => state.months.numbers,
  getAuthorizations: (state) => state.authorizations,
  getServices: (state) => state.services,
  getCerCodes: (state) => state.cerCodes,
  getAvailableCerCodes: (state) => state.availableCerCodes,
};

export default getters;
