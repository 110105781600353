import { http } from "../../../helpers/http";
import router from "../../../router";

const actions = {
  fetchTransporters: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.get(`companies/${companyId}/transporters`);
      // const res = await http.get('transporters?_sort=plate&_order=asc')

      const { transporters } = res.data;

      const transporterWithBillRate = transporters.filter((el) => el.billRate > 0);
      const sum = transporterWithBillRate.reduce((prev, next) => {
        return prev + parseFloat(next.billRate || 0);
      }, 0);

      commit("SET_AVERAGE_BILL_RATE", {
        average: sum / Math.max(transporterWithBillRate.length, 1),
      });
      commit("SET_TRANSPORTERS", { transporters });
      return transporters;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  updateTransporter: async ({ dispatch }, newTransporter) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.patch(
        `companies/${companyId}/transporters/${newTransporter._id}`,
        newTransporter
      );
      const { transporter } = res.data;

      await dispatch("fetchTransporters"); //or replace with commit(ADD_TRANSPORTER) and sort

      return transporter;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  createTransporter: async ({ dispatch, commit }, newTransporter) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const res = await http.post(`companies/${companyId}/transporters`, newTransporter);
      const { transporter } = res.data;

      await dispatch("fetchTransporters"); //or replace with commit(ADD_TRANSPORTER) and sort
      commit("SET_TRANSPORTER", { transporter });
      return transporter;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  deleteTransporter: async ({ dispatch }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const { data } = await http.delete(`companies/${companyId}/transporters/${id}`);

      await dispatch("fetchTransporters");

      return data;
    } catch (error) {
      console.warn(error);
      return error.response.data;
    }
  },

  syncWithForms: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.post(`companies/${companyId}/transporters/sync`);

      const { transporters, count } = res.data;

      const sum = transporters.reduce((prev, next) => {
        return prev + parseFloat(next.billRate || 0);
      }, 0);

      commit("SET_AVERAGE_BILL_RATE", { average: sum / transporters.length });
      commit("SET_TRANSPORTERS", { transporters });

      return { transporters, count };
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  migrate: async ({ dispatch }, { to, from }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.post(`companies/${companyId}/transporters/migrate`, { to, from });

      const { modified } = res.data;

      if (modified >= 0) {
        await dispatch("deleteTransporter", from);
      }

      return modified;
    } catch (error) {
      console.warn(error);
      return error.response?.data;
    }
  },
};

export default actions;
