import { http, setToken, removeToken } from "../../../helpers/http";

const actions = {
  login: async ({ commit }, { username, password, companyId }) => {
    return await http
      .post(`login`, {
        password,
        username: username,
        companyId,
      })
      .then((res) => {
        const data = res.data;
        if (data.success) {
          setToken(data.jwt);
          const user = data.user;
          delete user.password;
          commit("SET_USER", { user });
          return { success: data.success, user };
        } else {
          return data.message;
        }
      })
      .catch((e) => {
        return e.response.data;
      });
  },

  logout: () => {
    removeToken();
  },

  fetchUser: async ({ commit, dispatch }) => {
    try {
      const token = localStorage.getItem("id_token");
      if (!token) throw "invalid token";

      setToken(token);

      const res = await http.get("users/token");

      const { user, success, jwt, companyId } = res.data;
      user.companyId = companyId;

      if (user?._id) {
        setToken(jwt);
        commit("SET_USER", { user });
        if (!companyId) dispatch("setAdminRoutes", undefined, { root: true });
        return { success: success, user };
      } else {
        throw "Errore durante il recupero dell'utente";
      }
    } catch (error) {
      console.warn(error);
    }
  },

  fetchUsers: async ({ commit }, companyId) => {
    try {
      const res = await http.get(companyId ? `companies/${companyId}/users` : `users`);
      const { users } = res.data;
      commit("SET_USERS", { users });
      return users;
    } catch (error) {
      commit("SET_USERS", { users: [] });
      return error;
    }
  },

  fetchUserById: async ({ commit }, id) => {
    const res = await http.get(`users/${id}`);

    return res.data;
  },

  createUser: async ({ commit, rootState }, user) => {
    try {
      const roles = rootState.roles.roles;
      const role = roles.find((el) => el._id === user.roleId);

      user.role = role.name;
      user.permissions = role.permissions;

      user.password = `${user.firstName}${user.lastName}`; //(Math.random() + 1).toString(36).substring(7)

      const res = await http.post(
        user.companyId ? `companies/${user.companyId}/users` : `users`,
        user
      );

      user._id = res.data.user?._id;
      delete user.password;
      commit("ADD_USER", { user });
      return user;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  updateUser: async ({ commit, rootGetters }, { user, permissions }) => {
    try {
      const permissionsFields = rootGetters["roles/getPermissions"];
      const availablePermissions = [];

      for (const key in permissionsFields) {
        availablePermissions.push(...permissionsFields[key].permissions.map((el) => el.key));
      }

      user.permissions = availablePermissions.reduce((prev, next) => {
        if (permissions[next]) prev.push(next);
        return prev;
      }, []);

      delete user.password;

      const res = await http.patch(
        user.companyId ? `companies/${user.companyId}/users/${user._id}` : `users/${user._id}`,
        user
      );
      const { data } = res;

      if (data.user?._id) commit("UPDATE_USER", { user });
      else throw "Error on saving user";
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  getPasswordResetToken: async ({ getters }) => {
    try {
      const user = getters["getUser"];

      const res = await http.get(
        user.companyId
          ? `companies/${user.companyId}/users/${user._id}/reset`
          : `users/${user._id}/reset`
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  resetPassword: async ({ getters }, { password, resetToken }) => {
    try {
      const user = getters["getUser"];

      if (!user || !resetToken) return;

      const res = await http.post(
        user.companyId
          ? `companies/${user.companyId}/users/${user._id}/confirm-reset`
          : `users/${user._id}/confirm-reset`,
        {
          password,
          resetToken,
        }
      );

      const { success } = res.data;
      return success;

      // const res = await http.post(user.companyId ? `companies/${user.companyId}/users/${user._id}` : `users/${user._id}`,)
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  deleteUser: async ({ commit }, user) => {
    try {
      const res = await http.delete(
        user.companyId ? `companies/${user.companyId}/users/${user._id}` : `users/${user._id}`
      );
      const { success } = res.data;

      if (success) commit("REMOVE_USER", { user });
      else throw "Error on saving user";
    } catch (error) {
      console.warn(error);
      return error;
    }
  },
};

export default actions;
