import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_DRIVERS] : (state,{drivers}) => {
    state.drivers = drivers
  },
  [TYPES.SET_DRIVER] : (state,{ driver }) => {
    state.driver = driver
  },
  [TYPES.SET_DRIVER_AVERAGE_BILL_RATE] : (state, { average }) => {
    state.averageBillRate = average
  }
}

export default mutations