export const CompanyLocalUnitsRoutes = [
  {
    path: "local-units",
    name: "Company.localUnits.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/CompanyLocalUnitsIndex.vue"),
    meta: {
      permissions: ["company:admin", "forms:read"],
    },
  },
];
