export const CompanyImportsRoutes = [
  {
    path: "imports",
    name: "Company.imports.index",
    component: () =>
      import(/* webpackChunkName: "group-imports" */ "@/views/CompanyImportsIndex.vue"),
    meta: {
      permissions: ["accounts:config", "invoices:config", "forms:config"],
    },
  },
];
