import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_TRANSPORTERS] : (state,{transporters}) => {
    state.transporters = transporters
  },
  [TYPES.SET_TRANSPORTER] : (state,{ transporter }) => {
    state.transporter = transporter
  },
  [TYPES.SET_AVERAGE_BILL_RATE] : (state, { average }) => {
    state.averageBillRate = average
  }
}

export default mutations