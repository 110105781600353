import store from "../store";

export const CompanyChecksRoutes = [
  {
    path: "checks",
    name: "Company.checks.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/CompanyCheckIndex.vue"),
    meta: {
      permissions: [...store.getters["roles/getAllPermissionsKeys"]],
    },
  },
];
