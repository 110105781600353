import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_COMPANIES] : (state,{companies}) => {
    state.companies = companies
  },
  [TYPES.SET_COMPANY] : (state,{company}) => {
    state.company = company
  },
  [TYPES.ADD_COMPANY]: (state,{company}) => {
    state.companies.push(company)
  },
  [TYPES.UPDATE_COMPANY]: (state,{company}) => {
    const c = state.companies.find( el => el._id === company._id)
    const i = state.companies.indexOf(c)
    if(i >= 0) 
      state.companies.splice(i,1,company).sort((a,b) => a.name < b.name)
  }
}

export default mutations