import router from "../../router";
import store from "../../store";

const CanView = async (to, from) => {
  const routePermissions = to.meta.permissions || [];

  // nessun permesso richiesto
  if (routePermissions.length === 0) return;

  let user = store.getters["users/getUser"];
  let company = store.getters["companies/getCompany"];

  // if user is not in the store
  if (!user) {
    try {
      const res = await store.dispatch("users/fetchUser");
      user = res.user;
    } catch (error) {
      store.dispatch("users/logout");
      return router.push({ name: to.meta.redirect });
    }
  }

  let access;
  routePermissions.forEach((element) => {
    if (user.permissions.includes(element)) {
      access = element;
    }
  });

  if (access) return;
  else if (user.permissions?.length > 0 && !user.permissions.includes("admin") && company) {
    // company user without dashboard permission land on profile page
    router.push({ name: "Company.profile", params: { companyId: company._id } });
  } else {
    router.push({ name: "403" });
  }
};

export default CanView;
