const state = () => ({
  year: getYear(),
  day: null,
  monthsRange: [0, 11],
  months: {
    labels: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ],
    numbers: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
  },
  authorizations: [],
  services: [],
  cerCodes: [],
  availableCerCodes: [],
});

function getYear() {
  let year = localStorage.getItem("selectedYear");

  if (!year) {
    year = new Date().getFullYear();
    localStorage.setItem("selectedYear", year);
  }

  return parseInt(year);
}

export default state;
