import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";

const actions = {
  fetchInvoices: async ({ commit, rootGetters }, { active = true, page = 1, limit = 100 } = {}) => {
    commit("SET_INVOICES", { invoices: [] });
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/invoices`);

      const module = active ? "invoices" : "passiveInvoices";
      const months = rootGetters["filters/getMonthsNumbers"];

      const year = rootGetters["filters/getYear"] || new Date().getFullYear();
      const monthsRange = rootGetters["filters/getMonthsRange"];

      const sort = rootGetters["getSort"](module);
      const order = rootGetters["getOrder"](module);

      // new method

      url.addQuery("incoming", "", !active, "?");

      // apply filters
      const filters = rootGetters["getFilters"](module);

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ");
          let operator = "";

          if (value.length > 1) {
            switch (value[0]) {
              case "<":
                operator = "lte";
                break;
              case ">":
                operator = "gte";
                break;
            }
          }

          url.addQuery(key, "", value.join(""));
        }
      }

      url.dateRange();
      // add date range query params
      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }

      // add order query params
      url.sort(sort, order).paged(page, limit);

      const res = await http.get(url.get());

      const { invoices, total = 0 } = res.data;

      commit("SET_INVOICES", { invoices });
      commit("setTotalCount", { totalCount: total, module }, { root: true });
    } catch (error) {
      return error;
    }
  },

  fetchActiveInvoices: async ({ dispatch }, { page = 1, limit = 100 } = {}) => {
    return dispatch("fetchInvoices", { active: true, page, limit });
  },

  fetchPassiveInvoices: async ({ dispatch }, { page = 1, limit = 100 } = {}) => {
    return dispatch("fetchInvoices", { active: false, page, limit });
  },

  createInvoice: async () => {
    // console.log("Not implemented");
  },

  updateInvoice: async ({ commit }, newInvoice) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/invoices/${newInvoice._id}`);

      const res = await http.patch(url.get(), newInvoice);

      const { invoice } = res.data;
      commit("SET_INVOICE", { invoice: newInvoice });
      if (invoice) {
        return res.data;
      }
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  fetchInvoice: async ({ commit }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/invoices/${id}`);
      const res = await http.get(url.get());

      const { invoice } = res.data;
      commit("SET_INVOICE", { invoice });
    } catch (e) {
      console.warn(e);
    }
  },

  createInvoiceRow: async ({ commit }, invoiceRow) => {
    try {
      const res = await http.post(`invoiceRows`, invoiceRow);

      return res.data;
    } catch (e) {
      console.warn(e);
    }
  },

  fetchYearDataset: async ({ commit, rootGetters }, { incoming } = {}) => {
    const year = rootGetters["filters/getYear"];

    if (!year) return;

    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/invoices/chart/year?`);

      url.addQuery("year", "", year);

      if (Boolean(incoming)) {
        url.addQuery("incoming", "", 1);
      }

      const res = await http.get(url.get());
      const { dataset } = res.data;

      if (dataset) {
        const months = rootGetters["filters/getMonths"];

        const newDataset = months.map((el, i) => {
          const data = dataset.find((el) => el._id.month == i + 1);

          return {
            month: i,
            label: el,
            total: data ? data.total : 0,
          };
        });

        newDataset.sort((a, b) => {
          return a.month - b.month;
        });

        commit("SET_DATASET", { key: "year", dataset: newDataset });
        return dataset;
      }
    } catch (error) {
      console.warn(error);
      return error;
    }
  },
};

export default actions;
