export const CompanyFormsRoutes = [
  {
    path: "forms",
    name: "Company.forms.index",
    component: () => import(/* webpackChunkName: "group-forms" */ "@/views/CompanyFormsIndex.vue"),
    meta: {
      permissions: ["forms:read"],
    },
  },
  {
    path: "forms/:id",
    name: "Company.forms.show",
    component: () => import(/* webpackChunkName: "group-forms" */ "@/views/CompanyFormsShow.vue"),
    meta: {
      permissions: ["forms:read"],
    },
  },
];
