const state = () => ({
  accounts: [],
  account: null,
  categories: [],
  totalCount: 0,
  filters: {},
  sort: "_id",
  defaultSort: "_id",
  order: "asc",
});

export default state;
