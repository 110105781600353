import * as TYPES from "../../../store/mutation-types";

const mutations = {
  [TYPES.SET_REPORT]: (state, { report, key }) => {
    if (key === "lines") state.reports[key].unshift(report);
    else state.reports[key] = report;
  },
};

export default mutations;
