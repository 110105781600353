const state = () => ({
  roles: [],
  role: null,
  scopes: { administrator: 1, company: 2 },
  permissions: {
    admin: {
      scope: 1,
      name: "Amministratore",
      permissions: [
        {
          key: "admin",
          label: "Admin",
          text: "Accesso completo all'applicativo",
        },
      ],
    },
    users: {
      scope: 1,
      name: "Utenti",
      permissions: [
        {
          key: "users:read",
          label: "Lettura",
          text: "Accesso in lettura ai dati degli utenti",
        },
        {
          key: "users:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai dati degli utenti",
        },
      ],
    },
    companies: {
      scope: 1,
      name: "Aziende",
      permissions: [
        {
          key: "companies:read",
          label: "Lettura",
          text: "Accesso in lettura ai dati delle aziende",
        },
        {
          key: "companies:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai dati delle aziende",
        },
      ],
    },
    manager: {
      scope: 2,
      name: "Manager aziendale",
      permissions: [
        {
          key: "company:admin",
          label: "Manager",
          text: "Accesso completo ai dati dell'azienda",
        },
      ],
    },
    manager: {
      scope: 2,
      name: "Dashboard",
      permissions: [
        {
          key: "company:dashboard",
          label: "Dashboard",
          text: "Accesso alla Dashboard",
        },
      ],
    },
    report: {
      scope: 2,
      name: "Report",
      permissions: [
        {
          key: "report:ce",
          label: "Report CE",
          text: "Accesso al report CE",
        },
        {
          key: "report:lines",
          label: "Report Linee di servizio",
          text: "Accesso al report per linea di servizio",
        },
        {
          key: "report:producer",
          label: "Report Produttori",
          text: "Accesso al report per produttore",
        },
        {
          key: "report:broker",
          label: "Report Intermediari",
          text: "Accesso al report per intermediario",
        },
        {
          key: "report:quantities",
          label: "Report Quantità",
          text: "Accesso al report delle quantità",
        },
        {
          key: "report:accounts",
          label: "Report Contabilità",
          text: "Accesso al report della contabilità",
        },
        {
          key: "report:config",
          label: "Configurazioni",
          text: "Accesso alle configurazioni dei report",
        },
      ],
    },
    authorizations: {
      scope: 2,
      name: "Autorizzazioni",
      permissions: [
        {
          key: "authorizations:read",
          label: "Lettura",
          text: "Accesso in lettura ai dati delle autorizzazioni",
        },
        {
          key: "authorizations:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai dati delle autorizzazioni",
        },
      ],
    },
    lines: {
      scope: 2,
      name: "Linee di servizio",
      permissions: [
        {
          key: "lines:read",
          label: "Lettura",
          text: "Accesso in lettura ai dati delle linee di servizio",
        },
        {
          key: "lines:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai dati delle linee di servizio",
        },
        {
          key: "lines:config",
          label: "Configurazione",
          text: "Accesso alle configurazioni delle linee di servizio",
        },
      ],
    },
    flows: {
      scope: 2,
      name: "Flussi di servizio",
      permissions: [
        {
          key: "flows:read",
          label: "Lettura",
          text: "Accesso in lettura ai dati dei flussi di servizio",
        },
        {
          key: "flows:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai dati dei flussi di servizio",
        },
        {
          key: "flows:config",
          label: "Configurazione",
          text: "Accesso alle configurazioni dei flussi di servizio",
        },
      ],
    },
    costs: {
      scope: 2,
      name: "Costi standard",
      permissions: [
        {
          key: "costs:read",
          label: "Lettura",
          text: "Accesso in lettura ai costi standard",
        },
        {
          key: "costs:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai costi standard",
        },
        {
          key: "costs:config",
          label: "Configurazione",
          text: "Accesso alle configurazioni dei costi standard",
        },
      ],
    },
    forms: {
      scope: 2,
      name: "Formulari",
      permissions: [
        {
          key: "forms:read",
          label: "Lettura",
          text: "Accesso in lettura ai dati dei formulari",
        },
        {
          key: "forms:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai dati dei formulari",
        },
        {
          key: "forms:config",
          label: "Configurazione",
          text: "Accesso alle configurazioni dei formulari",
        },
      ],
    },
    invoices: {
      scope: 2,
      name: "Fatture",
      permissions: [
        {
          key: "invoices:read",
          label: "Lettura",
          text: "Accesso in lettura ai dati delle fatture",
        },
        {
          key: "invoices:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai dati delle fatture",
        },
        {
          key: "invoices:config",
          label: "Configurazione",
          text: "Accesso alle configurazioni delle fatture",
        },
      ],
    },
    generalAccounts: {
      scope: 2,
      name: "Contabilità generale",
      permissions: [
        {
          key: "accounts:read",
          label: "Lettura",
          text: "Accesso in lettura ai dati contabilità generale",
        },
        {
          key: "accounts:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai dati contabilità generale",
        },
        {
          key: "accounts:config",
          label: "Configurazione",
          text: "Accesso alle configurazioni contabilità generale",
        },
      ],
    },
    reclassifiedAccounts: {
      scope: 2,
      name: "Contabilità riclassificata",
      permissions: [
        {
          key: "reclassified:read",
          label: "Lettura",
          text: "Accesso in lettura ai dati contabilità riclassificata",
        },
        {
          key: "reclassified:write",
          label: "Scrittura",
          text: "Accesso in scrittura ai dati contabilità riclassificata",
        },
        // {
        //     key: 'reclassified:config',
        //     label: 'Configurazione',
        //     text: 'Accesso alle configurazioni contabilità riclassificata'
        // }
      ],
    },
    register: {
      scope: 2,
      name: "Registro ore",
      permissions: [
        {
          key: "register:read",
          label: "Lettura",
          text: "Accesso in lettura registro ore",
        },
        {
          key: "register:write",
          label: "Scrittura",
          text: "Accesso in scrittura registro ore",
        },
        {
          key: "register:config",
          label: "Configurazione",
          text: "Accesso alle configurazioni del registro ore",
        },
      ],
    },
  },
});

export default state;
