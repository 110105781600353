import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_SERVICES] : (state,{services}) => {
    state.services = services
  },
  [TYPES.SET_SERVICE] : (state,{service}) => {
    state.service = service
  },
  [TYPES.ADD_SERVICE]: (state,{service}) => {
    state.services.push(service)
  },
  [TYPES.UPDATE_SERVICE]: (state,{service}) => {
    const a = state.services.find( el => el._id === service._id)
    const i = state.services.indexOf(a)
    if(i >= 0) 
      state.services.splice(i,1,service)
  },
  [TYPES.REMOVE_SERVICE]: (state,id) => {
    const a = state.services.find( el => el._id === id)
    const i = state.services.indexOf(a)
    if(i >= 0)
      state.services.splice(i,1)
  }
}

export default mutations