const state = () => ({
    authorizations: [],
    authorization: null,
    types: [
        {
            id: 1,
            name: 'Impianto',
            value: 'plant'
        },
        {
            id: 2,
            name: 'Trasporto',
            value: 'transporter'
        },
        {
            id: 3,
            name: 'Intermediazione',
            value: 'broker'
        },
    ]
})

export default state;