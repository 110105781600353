import axios from 'axios'

const apiUrl = '/api/'

export const http = axios.create({
  baseURL: apiUrl
})

export const httpFake = axios.create({
  baseURL: 'https://secret-sands-64980.herokuapp.com/'  //'http://localhost:3004/'
})

export const setToken = (token) => {
  http.defaults.headers.common.Authorization = `${token}`
  localStorage.setItem('id_token', token);
}

export const removeToken = () => {
  http.defaults.headers.common.Authorization = undefined
  localStorage.removeItem('id_token');
}
