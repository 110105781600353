import * as TYPES from '../../../store/mutation-types'

const mutations = {
  [TYPES.SET_OPTIONS] : (state,{options}) => {
    state.options = options
  },
  [TYPES.SET_DEFAULT_OPTIONS] : (state,{options}) => {
    state.defaultOptions = options
  },
  [TYPES.ADD_OPTION]: (state,{option}) => {
    state.options.push(option)
  },
  [TYPES.UPDATE_OPTION]: (state,{option}) => {
    const find = state.options.find(el => el.key == option.key )
    const i = state.options.indexOf(find)
    if( i >= 0)
      state.options.splice(i,1,option)
  }
}

export default mutations