import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";

const actions = {
  fetchAccounts: async ({ commit }) => {
    const companyId = router.currentRoute.value.params.companyId;
    const url = new Url(`companies/${companyId}/reclassified/accounts`);

    let res = await http.get(url.get());

    let { accounts } = res.data;

    if (accounts) {
      commit("SET_RECLASSIFIED_ACCOUNTS", { accounts });
      return accounts;
    } else {
      throw "Error fetching accounts";
    }
  },

  createAccount: async ({ commit }, newAccount) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/accounts`);

      let res = await http.post(url.get(), newAccount);

      const { account } = res.data;

      if (account) {
        commit("ADD_RECLASSIFIED_ACCOUNT", { account });
        return account;
      } else {
        throw "Error saving account";
      }
    } catch (error) {
      return error?.response?.data;
    }
  },

  updateAccount: async ({ commit }, newAccount) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/accounts/${newAccount._id}`);
      let res = await http.patch(url.get(), newAccount);

      const { account } = res.data;

      if (account) {
        commit("UPDATE_RECLASSIFIED_ACCOUNT", { account });
        return account;
      } else {
        throw "Error updating account";
      }
    } catch (error) {
      return error?.response?.data;
    }
  },

  deleteAccount: async ({ commit }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/accounts/${id}`);
      await http.delete(url.get());

      commit("REMOVE_ACCOUNT", id);
      return { id };
    } catch (error) {
      return error?.response?.data;
    }
  },

  fetchTypes: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/types`);

      let res = await http.get(url.get());

      let { types } = res.data;

      if (types) {
        commit("SET_RECLASSIFIED_ACCOUNT_TYPES", { types });
        return types;
      } else {
        throw "Error fetching types";
      }
    } catch (error) {
      return error;
    }
  },

  fetchCategories: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/categories`);

      let res = await http.get(url.get());

      let { categories } = res.data;

      if (categories) {
        commit("SET_RECLASSIFIED_ACCOUNT_CATEGORIES", { categories });
        return categories;
      } else {
        throw "Error fetching categories";
      }
    } catch (error) {
      return error;
    }
  },

  updateCategory: async ({ commit }, newCategory) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/categories/${newCategory.id}`);

      let res = await http.patch(url.get(), newCategory);

      const { category } = res.data;
      if (category) {
        commit("UPDATE_RECLASSIFIED_ACCOUNT_CATEGORY", { category });
      } else {
        throw "Error saving category";
      }
    } catch (error) {
      return error;
    }
  },

  fetchNatures: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/natures`);

      let res = await http.get(url.get());

      let { natures } = res.data;

      if (natures) {
        commit("SET_RECLASSIFIED_ACCOUNT_NATURES", { natures });
        return natures;
      } else {
        throw "Error fetching natures";
      }
    } catch (error) {
      return error;
    }
  },

  createNature: async ({ commit, getters }, newNature) => {
    try {
      const categories = getters["getCategories"];
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/natures`);

      let res = await http.post(url.get(), newNature);

      const { nature } = res.data;

      if (nature) {
        const cat = categories.find((el) => el._id == nature.accountCategoryId);
        nature.category = [cat];
        commit("ADD_RECLASSIFIED_ACCOUNT_NATURE", { nature });

        return res.data;
      } else {
        throw "Error saving category";
      }
    } catch (error) {
      return error?.response?.data;
    }
  },

  updateNature: async ({ commit, getters }, newNature) => {
    try {
      const categories = getters["getCategories"];
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/natures/${newNature._id}`);

      let res = await http.patch(url.get(), newNature);

      const { nature } = res.data;

      if (nature) {
        const cat = categories.find((el) => el._id == nature.accountCategoryId);
        nature.category = [cat];
        commit("UPDATE_RECLASSIFIED_ACCOUNT_NATURE", { nature });

        return res.data;
      } else {
        throw "Error saving category";
      }
    } catch (error) {
      return error?.response?.data;
    }
  },

  deleteNature: async ({ commit }, nature) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/reclassified/natures/${nature._id}`);

      let res = await http.delete(url.get(), nature);

      commit("REMOVE_NATURE", nature._id);
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
};

export default actions;
