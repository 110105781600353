const getters = {
  getRegisters: (state) => state.registers,
  getUnregistered: (state) => state.unregistered,
  getRegister: (state) => state.register,
  getUnregisteredCount: (state) => state.unregisteredCount,
  getGroup: (state) => state.group,
  getSelectedForms: (state) => state.selectedForms,
  getFormIdFromGroup:
    (state) =>
    ({ members }) => {
      const form = (state.registers || []).find((form) => {
        return members.includes(form.formCode);
      });

      return form._id;
    },
};

export default getters;
