import * as TYPES from "../../../store/mutation-types";

const mutations = {
  [TYPES.SET_LOCAL_UNITS]: (state, { units }) => {
    state.units = units;
  },
  [TYPES.SET_LOCAL_UNIT]: (state, { unit }) => {
    state.unit = unit;
  },
  [TYPES.UPDATE_LOCAL_UNIT]: (state, { unit }) => {
    const u = state.units.find((el) => el._id === unit._id);
    const i = state.units.indexOf(u);

    if (i >= 0) state.units.splice(i, 1, unit);
  },
  [TYPES.REMOVE_LOCAL_UNIT]: (state, id) => {
    const a = state.units.find((el) => el._id === id);
    const i = state.units.indexOf(a);
    if (i >= 0) state.units.splice(i, 1);
  },
};

export default mutations;
