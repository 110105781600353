import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";
import { useOptions } from "../../../hooks/useOptions";

const actions = {
  fetchAuthorizations: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let res = await http.get(`companies/${companyId}/authorizations`);

      let { authorizations } = res.data;

      commit("SET_AUTHORIZATIONS", { authorizations });
    } catch (error) {
      return error;
    }
  },

  createAuthorization: async ({ commit }, newAuthorization) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let res = await http.post(`companies/${companyId}/authorizations/`, newAuthorization);
      const { authorization } = res.data;

      if (authorization) {
        commit("ADD_AUTHORIZATION", { authorization });
        return authorization;
      } else {
        return res;
      }
    } catch (e) {
      return e.response.data;
    }
  },

  updateAuthorization: async ({ commit }, newAuthorization) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let res = await http.patch(
        `companies/${companyId}/authorizations/${newAuthorization._id}`,
        newAuthorization
      );
      const { authorization } = res.data;

      if (authorization) {
        commit("UPDATE_AUTHORIZATION", { authorization });
        return authorization;
      } else {
        throw Error("Errore nel salvataggio");
      }
    } catch (e) {
      console.warn(e);
      return e.response.data;
    }
  },

  deleteAuthorization: async ({ commit }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.delete(`companies/${companyId}/authorizations/${id}`);

      commit("REMOVE_AUTHORIZATION", id);
    } catch (e) {
      return e.response.data;
    }
  },

  addServiceLine: async ({ dispatch }, { authorization, newLine }) => {
    if (!newLine) return;
    const auth = JSON.parse(JSON.stringify(authorization));
    auth.serviceLines.push(newLine);

    return dispatch("updateAuthorization", auth);
  },

  updateServiceLine: async ({ commit }, { authorization, newLine, oldLine }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(`companies/${companyId}/authorizations/${authorization._id}/lines`);

      const res = await http.put(url.get(), { newLine, oldLine });

      const { authorization: toUpdate } = res.data;

      if (toUpdate) {
        commit("UPDATE_AUTHORIZATION", { authorization: toUpdate });
        return toUpdate;
      } else {
        throw Error("Errore nel salvataggio");
      }
    } catch (e) {
      return e.response.data;
    }
  },

  removeServiceLine: async ({ dispatch }, { authorization, line }) => {
    const i = authorization.serviceLines.indexOf(line);

    if (i >= 0) {
      const auth = JSON.parse(JSON.stringify(authorization));
      auth.serviceLines.splice(i, 1);
      return dispatch("updateAuthorization", auth);
    }

    return null;
  },

  updateStandardCosts: async ({ commit, rootGetters }, { costs, authorization, line }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const url = new Url(
        `companies/${companyId}/authorizations/${authorization._id}/lines/${line}/costs`
      );

      costs = JSON.parse(JSON.stringify(costs));

      const weightFormat = rootGetters["options/getOptionByKey"]("weight");

      let mappedCosts = {};

      for (const key in costs) {
        const [year, ...months] = key.split("-");
        const value = costs[key];

        const scalar = weightFormat?.value?.value || 1;

        for (const key in value) {
          const { costs: c = 0, revenue: r = 0 } = value[key];
          value[key].costs = c * scalar;
          value[key].revenue = r * scalar;
        }

        months.forEach((month) => {
          const k = `${year}-${month}`;
          mappedCosts[k] = value;
        });
      }

      const res = await http.put(url.get(), { costs: mappedCosts });
      const { authorization: auth } = res.data;

      if (auth) {
        commit("UPDATE_AUTHORIZATION", { authorization: auth });
      }

      return auth;
    } catch (error) {
      console.warn(error);
      return error.response?.data;
    }
  },

  updateAuthorizationPriorities: async ({ dispatch }, { orderedAuthorizations }) => {
    const companyId = router.currentRoute.value.params.companyId;
    const url = new Url(`companies/${companyId}/authorizations/priorities`);

    // console.log(orderedAuthorizations);
    for await (const auth of orderedAuthorizations) {
      // console.log(auth);
      await dispatch("updateAuthorization", auth);
    }
    // const res = await http.put(url.get(),{ authorizations: authorizationsOrdered })
  },
};

export default actions;
