import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";
import { useDateble } from "../../../hooks/useDateble";

const { dateToFormat } = useDateble();

const actions = {
  fetchProducerDashboard: async ({ commit, rootGetters }) => {
    const companyId = router.currentRoute.value.params.companyId;
    let url = new Url(`companies/${companyId}/dashboards/producer-01?`); // add /producer-01
    let availableCerUrl = new Url(`companies/${companyId}/cer?`);

    let authorizationsFilter = rootGetters["filters/getAuthorizations"] || [];
    let servicesFilter = rootGetters["filters/getServices"] || [];
    let cerCodesFilter = rootGetters["filters/getCerCodes"] || [];
    const authorizations = rootGetters["authorizations/getAuthorizations"];
    const availableLines = authorizations
      .filter((auth) => {
        if (authorizationsFilter.length === 0) return true;
        return authorizationsFilter.includes(auth._id);
      })
      .reduce((acc, next) => {
        acc.push(...next.serviceLines);
        return acc;
      }, []);

    // console.log("lines:", availableLines);
    // console.log("originale", servicesFilter, authorizationsFilter);

    // if (authorizationsFilter.length === 0) {
    //   authorizationsFilter = authorizations.map((auth) => auth._id);
    // } else {
    authorizationsFilter = authorizationsFilter.filter((id) =>
      authorizations.find((auth) => auth._id === id)
    );
    // }

    // if (servicesFilter.length === 0) {
    //   servicesFilter = availableLines.map((line) => line.name);
    // } else {
    servicesFilter = servicesFilter.filter((name) =>
      availableLines.find((line) => line.name === name)
    );
    // }

    // cerCodesFilter =

    // console.log("authorization filter", authorizationsFilter);
    // console.log("services filter", servicesFilter);

    url.dateRange();
    availableCerUrl.dateRange();

    const params = {
      authorizations: authorizationsFilter,
      services: servicesFilter,
    };

    const [res, resCer] = await Promise.all([
      http.get(url.get(), {
        params: {
          ...params,
          cer: cerCodesFilter,
        },
      }),
      http.get(availableCerUrl.get(), {
        params,
      }),
    ]);

    // const res = await http.get(url.get(), {
    //   params,
    // });

    const { dashboard } = res.data;
    const { cerCodes } = resCer.data;

    commit("filters/SET_AVAILABLE_CER_CODES", { availableCerCodes: cerCodes }, { root: true });
    commit("SET_DASHBOARD", { key: "producer", data: dashboard });
  },
  fetchMainDashboard: async ({ commit }) => {
    const companyId = router.currentRoute.value.params.companyId;
    let url = new Url(`companies/${companyId}/dashboards?`);

    // const year = rootGetters["filters/getYear"] || new Date().getFullYear();
    // // url += `&date_like=${year}`

    // const weightFormat = rootGetters["options/getOptionByKey"]("weight");

    // const monthsRange = rootGetters["filters/getMonthsRange"];
    // const months = rootGetters["filters/getMonthsNumbers"];

    url.dateRange();
    // if (monthsRange["0"] >= 0) {
    //   const data = new Date(year, monthsRange[0], 1);
    //   url.addQuery("fromDate", "", dateToFormat(data, "YYYY-MM-DD"));
    // }

    // if (monthsRange["1"] >= 0) {
    //   const data = new Date(year, monthsRange[1] + 1, 0);
    //   url.addQuery("toDate", "", dateToFormat(data, "YYYY-MM-DD"));
    // }

    const res = await http.get(url.get());

    const { dashboard } = res.data;

    commit("SET_DASHBOARD", { key: "main", data: dashboard });
  },
};

export default actions;
