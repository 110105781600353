import { http } from "../../../helpers/http";
import router from "../../../router";

const actions = {
  // upload csv mapped data
  import: async ({ commit }, { data }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      const res = await http.post(`companies/${companyId}/data-import/import`, data);

      // if (option) {
      //   commit("UPDATE_OPTION", { option });
      return true;
      // }
    } catch (error) {
      console.warn(error);
      return error?.response;
    }
  },
};

export default actions;
