import * as TYPES from "../../../store/mutation-types";

const mutations = {
  [TYPES.ADD_NOTIFICATION]: (state, { notification }) => {
    state.notifications.unshift(notification);
  },
  [TYPES.REMOVE_NOTIFICATION]: (state, id) => {
    const n = state.notifications.find((el) => el.id === id);
    const i = state.notifications.indexOf(n);
    if (i >= 0) {
      state.notifications.splice(i, 1);
    }
  },
};

export default mutations;
