export const CompanyAuthorizationsRoutes = [
  {
    path: "authorizations",
    name: "Company.authorizations.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/CompanyAuthorizationsIndex.vue"),
    meta: {
      permissions: ["authorizations:read"],
    },
  },
];
