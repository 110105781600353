import { http } from "../../../helpers/http";
import router from "../../../router";

const actions = {
  fetchDrivers: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.get(`companies/${companyId}/drivers`);

      const { drivers } = res.data;

      const driversWithBillRate = drivers.filter((el) => el.billRate > 0);
      const sum = driversWithBillRate.reduce((prev, next) => {
        return prev + parseFloat(next.billRate);
      }, 0);

      commit("SET_DRIVER_AVERAGE_BILL_RATE", {
        average: sum / Math.max(driversWithBillRate.length, 1),
      });
      commit("SET_DRIVERS", { drivers });
      return drivers;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  updateDriver: async ({ dispatch }, newDriver) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.patch(`companies/${companyId}/drivers/${newDriver._id}`, newDriver);
      const { driver } = res.data;

      await dispatch("fetchDrivers"); //or replace with commit(ADD_TRANSPORTER) and sort

      return driver;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  createDriver: async ({ dispatch, commit }, newDriver) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const res = await http.post(`companies/${companyId}/drivers`, newDriver);

      const { driver } = res.data;

      await dispatch("fetchDrivers"); //or replace with commit(ADD_TRANSPORTER) and sort
      commit("SET_DRIVER", { driver });
      return driver;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  deleteDriver: async ({ dispatch }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      const { data } = await http.delete(`companies/${companyId}/drivers/${id}`);

      await dispatch("fetchDrivers");

      return data;
    } catch (error) {
      console.warn(error);
      return error.response.data;
    }
  },

  syncWithForms: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.post(`companies/${companyId}/drivers/sync`);

      const { drivers, count } = res.data;

      const sum = drivers.reduce((prev, next) => {
        return prev + parseFloat(next.billRate);
      }, 0);

      commit("SET_DRIVER_AVERAGE_BILL_RATE", { average: sum / drivers.length });
      commit("SET_DRIVERS", { drivers });
      return { drivers, count };
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  migrate: async ({ dispatch }, { to, from }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;
      let res = await http.post(`companies/${companyId}/drivers/migrate`, { to, from });

      const { modified } = res.data;

      if (modified >= 0) {
        await dispatch("deleteDriver", from);
      }

      return modified;
    } catch (error) {
      console.warn(error);
      return error.response?.data;
    }
  },
};

export default actions;
