const getters = {
  getForms: (state) => state.forms,
  getForm: (state) => state.form,
  getAnalyticalData: (state) => state.analyticalData,
  getShowStandard: (state) => state.showStandard,
  getPerTons: (state) => state.perTons,
  getColumnsToggle: (state) => state.columnsToggle,
};

export default getters;
