import { http } from "../../../helpers/http";

const actions = {
  fetchRoles: async ({ commit }) => {
    let res = await http.get("roles");

    let { roles } = res.data;

    commit("SET_ROLES", { roles });
  },

  setRole: ({ commit }, role) => {
    commit("SET_ROLE", { role });
  },

  createRole: ({ commit }, role) => {
    http
      .post(`roles`, role)
      .then((res) => {
        const { role } = res.data;
        commit("ADD_ROLE", { newRole: role });
        commit("SET_ROLE", { role });
      })
      .catch((e) => {
        console.warn(e);
      });
  },

  updateRole: ({ commit }, role) => {
    return http
      .patch(`roles/${role._id}`, role)
      .then((res) => {
        const newRole = res.data.role;
        commit("SET_ROLE", { role: newRole });
        commit("UPDATE_ROLE", { newRole });
        return;
      })
      .catch((e) => {
        console.warn(e);
      });
  },

  deleteRole: ({ commit }, role) => {
    http
      .delete(`roles/${role._id}`)
      .then((res) => {
        commit("SET_ROLE", { role: null });
        commit("DELETE_ROLE", { id: role._id });
      })
      .catch((e) => {
        console.warn(e);
      });
  },
};

export default actions;
