import { http } from "../../../helpers/http";
import Url from "../../../helpers/url";
import router from "../../../router";

const actions = {
  fetchAccounts: async ({ commit, rootGetters }, { page = 100, limit } = {}) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/accounts/general?`);

      const sort = rootGetters["getSort"]("generalAccounts");
      const order = rootGetters["getOrder"]("generalAccounts");

      const filters = rootGetters["getFilters"]("generalAccounts");

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ");
          let operator = "";

          if (value.length > 1) {
            switch (value[0]) {
              case "<":
                operator = "lte";
                break;
              case ">":
                operator = "gte";
                break;
            }
          }

          url.addQuery(key, operator, value[1] ?? value[0]);
        }
      }

      url.sort(sort, order).paged(page, limit);

      let res = await http.get(url.get());

      let { accounts, total = 0 } = res.data;

      commit("SET_GENERAL_ACCOUNTS", { accounts });
      commit("setTotalCount", { totalCount: total, module: "generalAccounts" }, { root: true });
    } catch (error) {
      commit("SET_GENERAL_ACCOUNTS", { accounts: [] });
      commit("setTotalCount", { totalCount: 0, module: "generalAccounts" }, { root: true });
      console.warn(error);
    }
  },

  // TODO getAccount: async () => {},

  createAccount: async ({ commit }, newAccount) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/accounts/general`);
      let res = await http.post(url.get(), newAccount);
      const { account } = res.data;

      commit("ADD_GENERAL_ACCOUNT", { account });
      return res.data;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  updateAccount: async ({ commit }, newAccount) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/accounts/general/${newAccount._id}`);
      let res = await http.patch(url.get(), newAccount);
      const { account } = res.data;

      commit("UPDATE_GENERAL_ACCOUNT", { account });
      return res.data;
    } catch (error) {
      console.warn(error);
      return error?.response?.data;
    }
  },

  deleteAccount: async ({ commit }, id) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/accounts/general/${id}`);
      let res = await http.delete(url.get());

      commit("REMOVE_GENERAL_ACCOUNT", id);
    } catch (error) {
      console.warn(error);
      return error;
    }
  },
};

export default actions;
