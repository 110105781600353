import * as TYPES from "../../../store/mutation-types";

const mutations = {
  [TYPES.SET_REGISTERS]: (state, { registers }) => {
    state.registers = registers;
  },
  [TYPES.SET_UNREGISTERED]: (state, { unregistered }) => {
    state.unregistered = unregistered;
  },
  [TYPES.SET_UNREGISTERED_COUNT]: (state, { count }) => {
    state.unregisteredCount = count;
  },
  [TYPES.SET_REGISTER]: (state, { register }) => {
    state.register = register;
  },
  [TYPES.UPDATE_REGISTER]: (state, { register }) => {
    const a = state.registers.find((el) => el._id === register._id);
    const i = state.registers.indexOf(a);
    if (i >= 0) state.registers.splice(i, 1, register);
  },
  [TYPES.ADD_FORM_TO_GROUP]: (state, { form }) => {
    if (!state.selectedForms.includes(form.formCode)) {
      state.selectedForms.push(form.formCode);
    } else {
      console.warn("Formulario già selezionato");
    }
  },
  [TYPES.REMOVE_FORM_FROM_GROUP]: (state, { form }) => {
    const i = state.selectedForms.indexOf(form.formCode);
    if (i >= 0) {
      state.selectedForms.splice(i, 1);
    } else {
      console.warn("Non è stato possibile trovare il formulario.");
    }
  },
  [TYPES.REMOVE_ALL_FORMS_FROM_GROUP]: (state) => {
    state.selectedForms = [];
  },
  [TYPES.SELECT_GROUP]: (state, { group }) => {
    // console.log("mut select group", group);
    state.group = group;
    if (group) {
      state.selectedForms = [...group.members];
    } else {
      state.selectedForms = [];
    }
  },
  [TYPES.UPDATE_FORMS_WITH_GROUP_DATA]: (state, { members, remove = false }) => {
    state.registers.forEach((form) => {
      if (members.includes(form.formCode)) {
        if (remove) {
          delete form.group;
        } else {
          form.group = { members };
        }
      }
    });
  },
};

export default mutations;
