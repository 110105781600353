import { http } from "../../../helpers/http";
import router from "../../../router";
import Url from "../../../helpers/url";

const actions = {
  fetchRegisters: async (
    { commit, rootGetters },
    { limit, page, transporterAuthOnly = false } = {}
  ) => {
    try {
      // const months = rootGetters["filters/getMonthsNumbers"];
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/registers?`);

      // const year = rootGetters["filters/getYear"] || new Date().getFullYear();

      // const monthsRange = rootGetters["filters/getMonthsRange"];

      const filters = rootGetters["getFilters"]("registers");

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key) && filters[key]) {
          const value = filters[key].split(" ");
          let operator = "";

          if (value.length > 1) {
            switch (value[0]) {
              case "<":
                operator = "lte";
                break;
              case ">":
                operator = "gte";
                break;
            }
          }

          url.addQuery(key, operator, value[1] ?? value[0]);
        }
      }

      if (transporterAuthOnly) {
        url.addQuery("transporterAuthOnly", "=", "true");
      }

      url.dateRange();

      // if (monthsRange["0"] >= 0) {
      //   url.addQuery("fromDate", "", `${year}-${months[monthsRange[0]]}-01`);
      // }

      // if (monthsRange["1"] >= 0) {
      //   url.addQuery("toDate", "", `${year}-${months[monthsRange[1]]}-31`);
      // }

      const sort = rootGetters["getSort"]("registers");
      const order = rootGetters["getOrder"]("registers");

      url.sort(sort, order).paged(page, limit);

      const res = await http.get(url.get());

      const { registers, total = 0 } = res.data;

      commit("SET_REGISTERS", { registers });
      commit("setTotalCount", { totalCount: total, module: "registers" }, { root: true });
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  fetchUnregistered: async ({ commit, rootGetters }, { limit } = {}) => {
    try {
      const months = rootGetters["filters/getMonthsNumbers"];
      const companyId = router.currentRoute.value.params.companyId;
      let url = new Url(`companies/${companyId}/registers/unregistered?`);
      const filters = rootGetters["getFilters"]("registers");
      url.paged(1, limit);
      const res = await http.get(url.get());
      const { registers } = res.data;
      commit("SET_UNREGISTERED", { unregistered: registers });
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  updateRegister: async ({ commit, dispatch }, newForm) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let res = await http.patch(`companies/${companyId}/registers/${newForm._id}`, newForm);
      const { register } = res.data;

      if (register) {
        // commit('UPDATE_REGISTER',{ register })
        dispatch("fetchUnregisteredCount");
        return register;
      } else {
        throw Error("Errore nel salvataggio");
      }
    } catch (e) {
      console.warn(e);
      return e.response.data;
    }
  },

  fetchUnregisteredCount: async ({ commit }) => {
    try {
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/registers/unregistered/count`);

      const res = await http.get(url.get());

      const { total } = res.data;

      if (total) commit("SET_UNREGISTERED_COUNT", { count: total });
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  createGroupFromArray: async ({ commit, getters }) => {
    try {
      const selectedForms = getters["getSelectedForms"];
      const formId = getters["getFormIdFromGroup"]({ members: selectedForms });

      if (!formId || selectedForms.length <= 1 || !selectedForms) {
        console.warn("Nessun formulario selezionato per il gruppo");
        return;
      }

      const companyId = router.currentRoute.value.params.companyId;

      // console.log(selectedForms);
      // return;

      let url = new Url(`companies/${companyId}/forms/${formId}/group`);

      const res = await http.post(url.get(), { members: selectedForms });

      const { members } = res.data;

      if (members && members.length > 1) {
        commit("UPDATE_FORMS_WITH_GROUP_DATA", { members: selectedForms });
        commit("REMOVE_ALL_FORMS_FROM_GROUP");
      }

      return { members };
    } catch (error) {
      console.warn(error);
      return error;
    }
  },

  deleteGroupFromMemberId: async ({ commit, getters }) => {
    try {
      const selectedForms = getters["getSelectedForms"];
      const formId = getters["getFormIdFromGroup"]({ members: selectedForms });
      const companyId = router.currentRoute.value.params.companyId;

      let url = new Url(`companies/${companyId}/forms/${formId}/group`);

      await http.delete(url.get());

      commit("UPDATE_FORMS_WITH_GROUP_DATA", { members: [...selectedForms], remove: true });
      // commit("REMOVE_ALL_FORMS_FROM_GROUP");
      const forms = [...selectedForms];
      commit("SELECT_GROUP", { group: null });

      forms.forEach((formCode) => {
        commit("ADD_FORM_TO_GROUP", { form: { formCode } });
      });

      return { success: true };
    } catch (error) {
      console.warn(error);
      return error;
    }
  },
};

export default actions;
