<template>
  <AppLayout>
      <router-view />
  </AppLayout>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import AppLayout from './layouts/AppLayout.vue'
import { useRoute } from 'vue-router';

export default {
  name: 'App',
  components: {
    AppLayout,
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}
</style>
