export const CompanyTransportersRoutes = [
  {
    path: "transporters",
    name: "Company.transporters.index",
    component: () =>
      import(/* webpackChunkName: "group-company" */ "@/views/CompanyTransportersIndex.vue"),
    meta: {
      permissions: ["register:write"],
    },
  },
];
