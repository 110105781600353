import { createRouter, createWebHistory } from "vue-router";
import { AdminUserRoutes } from "./users";
import { AdminCompanyRoutes, CompanyRoutes } from "./companies";
import { AdminRolesRoutes } from "./roles";
import { AdminAccountsRoutes } from "./accounts";
// import Home from '../views/Home.vue';
import AuthAdmin from "../middlewares/auth/admin";
import GuestAdmin from "../middlewares/auth/guest";
import CanView from "../middlewares/auth/permissions";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "group-general" */ "@/views/Home.vue"),
    meta: {
      layout: "AppLayoutBlank",
    },
  },
  {
    path: "/admin",
    name: "Admin.dashboard",
    component: () => import(/* webpackChunkName: "group-admin" */ "@/views/admin/Dashboard.vue"),
    // beforeEnter: AuthAdmin,
    meta: {
      layout: "AdminDashboardLayout",
      requiresAuth: true,
      redirect: "Admin.login",
      permissions: ["admin", "users:read", "companies:read"],
    },
    children: [
      ...AdminCompanyRoutes,
      ...AdminUserRoutes,
      ...AdminRolesRoutes,
      // ...AdminAccountsRoutes
    ],
  },
  {
    path: "/admin/login",
    name: "Admin.login",
    component: () => import(/* webpackChunkName: "group-general" */ "@/views/admin/Login.vue"),
    meta: {
      layout: "AppLayoutBlank",
      requiresAuth: "guest",
      redirect: "Admin.dashboard",
    },
  },
  {
    path: "/company/:slug/login",
    component: () => import(/* webpackChunkName: "group-general" */ "@/views/CompanyLogin.vue"),
    props: true,
    meta: {
      permissions: [],
      layout: "AppLayoutBlank",
      requiresAuth: false,
      // redirect: 'Company.dashboard'
    },
  },
  {
    path: "/company/:companyId",
    // name: 'Company.dashboard',
    component: () => import(/* webpackChunkName: "group-company" */ "@/views/Company.vue"),
    meta: {
      layout: "AppCompanyLayout",
      requiresAuth: true,
    },
    children: [...CompanyRoutes],
  },
  {
    path: "/403",
    name: "403",
    component: () => import(/* webpackChunkName: "group-general" */ "@/views/403.vue"),
    meta: {},
  },
  {
    path: "/:all+",
    name: "404",
    component: () => import(/* webpackChunkName: "group-general" */ "@/views/404.vue"),
    meta: {},
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(GuestAdmin);
router.beforeEach(AuthAdmin);
router.beforeEach(CanView);

export default router;
