import { http } from "../../../helpers/http";
import router from "../../../router";

const actions = {
  fetchCompanies: async ({ commit }) => {
    try {
      let res = await http.get("companies");

      let companies = [...res.data.companies];

      commit("SET_COMPANIES", { companies });
    } catch (error) {
      return error;
    }
  },

  fetchCompany: async ({ commit }, id) => {
    try {
      let res = await http.get(`companies/${id}`);
      let { company } = res.data;

      if (!company) throw "Not found";

      commit("SET_COMPANY", { company });
      return company;
    } catch (error) {
      return error;
    }
  },

  fetchCompanyBySlug: async ({ commit }, slug) => {
    try {
      let res = await http.get(`companies/slug/${slug}`);
      const data = res.data;

      if (data.success) {
        return data;
      } else {
        // return router.push('/404' )
      }
    } catch (e) {
      console.warn(e);
      // return router.push('/404' )
    }
  },

  createCompany: async ({ commit }, newCompany) => {
    try {
      let res = await http.post(`companies`, newCompany);
      const { company } = res.data;

      if (company) {
        commit("ADD_COMPANY", { company });
        return company;
      } else {
        return res;
      }
    } catch (e) {
      return e.response.data;
    }
  },

  updateCompany: async ({ commit }, newCompany) => {
    try {
      let res = await http.patch(`companies/${newCompany._id}`, newCompany);
      const { company } = res.data;

      if (company) {
        commit("UPDATE_COMPANY", { company });
        return company;
      } else {
        return res;
      }
    } catch (e) {
      return e.response.data;
    }
  },

  updateCompanyField: async ({ commit }, { id, key, value }) => {
    try {
      const model = {
        id,
      };

      model[key] = value;

      let res = await http.patch(`companies/${id}`, model);

      const { company } = res.data;

      if (company) {
        commit("UPDATE_COMPANY", { company });
        return company;
      } else {
        return res;
      }
    } catch (error) {
      return e.response.data;
    }
  },
};

export default actions;
