const state = () => ({
  accounts: [],
  account: null,
  categories: [],
  natures: [],
  filters: {},
  sort: "code",
  defaultSort: "code",
  order: "asc",
});

export default state;
